import Select from 'react-select';
import { Obj } from 'interface/common';

interface PickerProps {
  name?: string;
  value?: number | string;
  placeholder?: string;
  options: Obj[];
  disabled?: boolean;

  onChange?(field: string, value: number): void;
  onBlur?(field: string, touched: boolean): void;
}

const Picker = (props: PickerProps) => {
  const handleChange = (value: {
    value: number;
    label: string;
  }) => {
    if (props.onChange) {
      props.onChange(props.name!, value.value);
    }
  }

  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur(props.name!, true);
    }
  };

  return (
    <Select isDisabled={props.disabled} value={
      props.options.find((item) => item.value === props.value)
    } options={props.options} placeholder={props.placeholder} onChange={handleChange} onBlur={handleBlur} />
  )
}

export default Picker;