import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Quill from 'quill';
import QuillBetterTable from 'quill-better-table';
import store from './redux-saga/store';
import reportWebVitals from './reportWebVitals';
import Router from './router';
import './index.scss';

Quill.register({
  'modules/better-table': QuillBetterTable
}, true);

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
