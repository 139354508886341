import * as React from 'react';
import BootstrapTable, { TableChangeHandler, ColumnDescription, TableChangeType, TableChangeState } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Obj } from 'interface/common';
import './styles.scss';

interface DataTableProps {
  data: Obj[];
  page: number;
  sizePerPage: number;
  onTableChange: (type: TableChangeType, newState: TableChangeState<Obj>, query?: boolean) => void;
  totalSize: number;
  columns: ColumnDescription[];
  keyField?: string;
  loading?: boolean;
  type?: TableChangeType;
  newState?: TableChangeState<Obj>;
}

export interface DataTableState {
  page: number;
  data: Obj[];
  totalSize: number;
  sizePerPage: number;
  filters: object;
}

const TableChangeStateBlank = {
  page: 0,
  sizePerPage: 0,
  sortField: "",
  sortOrder: ('asc' as "asc" | "desc"),
  filters: {},
  data: [],
  cellEdit: {
    rowId: "",
    dataField: "",
    newValue: "any",
  },
}

const NoDataIndication = () => (
  <></>
);


const DataTable = (props: DataTableProps) => {
  const { loading, data, page, sizePerPage, totalSize, columns, keyField, onTableChange, type, newState } = props;


  const sizePerPageRenderer = (data: any) => {
    return <DropdownButton variant="secondary" id="dropdown-basic-button" title={sizePerPage}>
      {(data.options).map((option: any, index: number) => {
        return <Dropdown.Item key={index} active={option.page === sizePerPage} onClick={() => data.onSizePerPageChange(option.page)}>{option.page}</Dropdown.Item>
      })}
    </DropdownButton>
  };

  const filterTable = (e: KeyboardEvent) => {
    const key = e.which || e.keyCode || 0;
    if (key === 13) {
      props.onTableChange("filter", TableChangeStateBlank, true);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keypress', filterTable);
    return () => {
      window.removeEventListener('keypress', filterTable)
    }
  }, [])

  return (
    <BootstrapTable
      bootstrap4
      remote
      keyField={keyField!}
      data={data}
      columns={columns}
      filter={filterFactory()}
      pagination={paginationFactory({ sizePerPageRenderer, page, sizePerPage, totalSize })}
      onTableChange={onTableChange}
      wrapperClasses="table-responsive"
      overlay={overlayFactory({ spinner: true })}
      {...{ loading: loading }}
      noDataIndication={() => <NoDataIndication />}
    />
  );
};

DataTable.defaultProps = {
  keyField: 'id'
}

export default React.memo(DataTable);
