import { createReducer } from 'utils/redux';

export const MARKET_PRICE_LOAD_ITEM_SUCCESS = 'MARKET_PRICE_LOAD_ITEM_SUCCESS';
export const MARKET_PRICE_LOAD_ITEM_FAILED = 'MARKET_PRICE_LOAD_ITEM_FAILED';
export const MARKET_PRICE_LOAD_ITEM_CLEAR = 'MARKET_PRICE_LOAD_ITEM_CLEAR';

export const MARKET_PRICE_ACTION_SUCCESS = 'MARKET_PRICE_ACTION_SUCCESS';
export const MARKET_PRICE_ACTION_FAILED = 'MARKET_PRICE_ACTION_FAILED';
export const MARKET_PRICE_ACTION_CLEAR = 'MARKET_PRICE_ACTION_CLEAR';

export const MarketPriceItem = createReducer(
    MARKET_PRICE_LOAD_ITEM_SUCCESS,
    MARKET_PRICE_LOAD_ITEM_FAILED,
    MARKET_PRICE_LOAD_ITEM_CLEAR
);

export const MarketPriceActionResult = createReducer(
    MARKET_PRICE_ACTION_SUCCESS,
    MARKET_PRICE_ACTION_FAILED,
    MARKET_PRICE_ACTION_CLEAR
);
