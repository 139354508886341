import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Quill, { RangeStatic, Sources } from 'quill';
import { State } from 'redux-saga/reducers';
import { FILE_UPLOAD_CLEAR } from './reducers';
import { uploadImage } from './actions';

interface ContentEditorProps {
  name?: string;
  onChange?(field: string, value: string): void;
  onBlur?(field: string, touched: boolean): void;
  initialValue?: string;
}

const ContentEditor = (props: ContentEditorProps) => {
  const dispatch = useDispatch();

  const fileUploadResult = useSelector(
    (state: State) => state.fileUploadResult,
    shallowEqual
  );

  const quillRef = React.useRef<Quill>();

  React.useEffect(() => {
    quillRef.current = new Quill('#editor', {
      theme: 'snow',
      placeholder: 'Nhập nội dung...',
      modules: {
        table: false,
        'better-table': {
          operationMenu: {
            items: {
              unmergeCells: {
                text: 'Another unmerge cells name'
              }
            },
            color: {
              colors: ['green', 'red', 'yellow', 'blue', 'white'],
              text: 'Background Colors:'
            }
          }
        },
        toolbar: {
          container: [
            [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }]
            
          ],
          handlers: {
            image: imageHandler
          }
        }
      }
    });

    quillRef.current.setText(props.initialValue!);
    quillRef.current.on('text-change', handleChange);
    quillRef.current.on('selection-change', handleBlur);

    return (() => {
      if (quillRef.current) {
        quillRef.current.off('text-change', handleChange);
        quillRef.current.off('selection-change', handleBlur);
      }
    })
  }, []);

  React.useEffect(() => {
    quillRef.current?.clipboard.dangerouslyPasteHTML(props.initialValue!);
  }, [props.initialValue]);

  React.useEffect(() => {
    if (fileUploadResult && fileUploadResult.success && fileUploadResult.response && quillRef.current) {
      const editor = quillRef.current;
      const range = editor.getSelection(true);
      editor.deleteText(range.index, 1);
      editor.insertEmbed(range.index, 'image', `${fileUploadResult.response.url}`);
      // Move cursor to right side of image (easier to continue typing)
      editor.setSelection((range.index + 1) as unknown as typeof range);
      dispatch({
        type: FILE_UPLOAD_CLEAR
      });
    }
  }, [fileUploadResult]);


  const handleChange = () => {
    if (props.onChange && quillRef.current) {
      props.onChange(props.name!, quillRef.current.root.innerHTML);
    }
  }

  const handleBlur = (range: RangeStatic, oldRange: RangeStatic, source: Sources) => {
    if (!range) {
      if (props.onBlur) {
        props.onBlur(props.name!, true);
      }
    }
  };

  const imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      if (input.files && quillRef.current) {
        const file = input.files[0];
        const formData = new FormData();

        formData.append('file', file);

        // Save current cursor state
        const editor = quillRef.current;
        const range = editor.getSelection(true);

        // Insert temporary loading placeholder image
        editor.insertEmbed(range.index, 'image', `/placeholder.png`);
        dispatch(uploadImage(formData));
      }

    };
  }

  return (
    <div>
      <div id="editor" />
    </div>
  )
}

ContentEditor.defaultProps = {
  name: 'content'
};

export default React.memo(ContentEditor);