import { Obj } from 'interface/common';
import { NEWS_LOAD_ITEM } from 'redux-saga/actions';
import { NewsForm } from '.';
import { 
  NEWS_ACTION_FAILED, 
  NEWS_ACTION_SUCCESS, 
  NEWS_LOAD_ITEM_FAILED, 
  NEWS_LOAD_ITEM_SUCCESS 
} from './reducers';

export const actionNews = (type: string, payload: NewsForm) => ({
  type,
  response: {
    success: {
      type: NEWS_ACTION_SUCCESS,
    },
    failure: {
      type: NEWS_ACTION_FAILED,
    },
  },
  payload
});

export const loadNews = (payload: Obj) => ({
  type: NEWS_LOAD_ITEM,
  response: {
    success: {
      type: NEWS_LOAD_ITEM_SUCCESS,
    },
    failure: {
      type: NEWS_LOAD_ITEM_FAILED,
    },
  },
  payload
});