import { PROFILE_CHANGE_PASSWORD } from 'redux-saga/actions';
import { ChangePasswordForm } from '.';
import { PROFILE_CHANGE_PASSWORD_SUCCESS, PROFILE_CHANGE_PASSWORD_FAILED } from './reducers';

export const changePassword = (payload: ChangePasswordForm) => ({
  type: PROFILE_CHANGE_PASSWORD,
  response: {
    success: {
      type: PROFILE_CHANGE_PASSWORD_SUCCESS,
    },
    failure: {
      type: PROFILE_CHANGE_PASSWORD_FAILED,
    },
  },
  payload
});