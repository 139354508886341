import React, { forwardRef, useEffect, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import DatePickerComp from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import { formatDateToString } from 'utils/datetime';
import './react-datepicker.css';
import './styles.scss';

interface DatePickerProps {
  name: string;
  placeholder?: string;
  isFilter?: boolean;
  value?: string;
  selected?: Date;
  className?: string;

  onChange?(field: string, value: string): void;
  onChangeFilter?(value: string): void;
  onBlur?(field: string, touched: boolean): void;
}

const DatePicker = (props: DatePickerProps) => {
  const {
    name,
    isFilter,
    value,
    selected,
    className,
  } = props;

  const onChange = (value: React.ChangeEvent) => {
    if (isFilter === false) {
      if (props.onChange) {
        props.onChange(props.name!, formatDateToString(value as unknown as Date, 'dd/MM/yyyy') as string);
      }
    } else {
      if (props.onChangeFilter) {
        props.onChangeFilter(formatDateToString(value as unknown as Date, 'dd/MM/yyyy') as string);
      }
    }
  }

  const onBlur = () => {
    if (props.onBlur) {
      props.onBlur(props.name!, true);
    }
  }

  const CustomInput = forwardRef((props: NumberFormatProps, ref: any) => (
    <InputGroup>
      <NumberFormat
        {...props}
        ref={ref}
        format="##/##/####"
        placeholder="DD/MM/YYYY"
        mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
        className="form-control"
      />
      <InputGroup.Append>
        <InputGroup.Text>
          <FaCalendarAlt size={12} />
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  ));

  return (
    <FormControl
      as={DatePickerComp}
      dateFormat="dd/MM/yyyy"
      name={name}
      autoComplete="off"
      onChange={onChange}
      onBlur={onBlur}
      selected={selected}
      customInput={<CustomInput />}
    />
  );
};

DatePicker.defaultProps = {
  isFilter: false,
};

export default DatePicker;
