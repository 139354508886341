import { createReducer } from "utils/redux";

export const QUESTION_QUERY_LIST_SUCCESS = 'QUESTION_QUERY_LIST_SUCCESS';
export const QUESTION_QUERY_LIST_FAILED = 'QUESTION_QUERY_LIST_FAILED';

export const QUESTION_ACTION_SUCCESS = 'QUESTION_ACTION_SUCCESS';
export const QUESTION_ACTION_FAILED = 'QUESTION_ACTION_FAILED';
export const QUESTION_ACTION_CLEAR = 'QUESTION_ACTION_CLEAR';

export const QuestionList = createReducer(
  QUESTION_QUERY_LIST_SUCCESS,
  QUESTION_QUERY_LIST_FAILED
);

export const QuestionActionResult = createReducer(
  QUESTION_ACTION_SUCCESS,
  QUESTION_ACTION_FAILED,
  QUESTION_ACTION_CLEAR
);
