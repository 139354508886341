import { DOCUMENT_CREATE, DOCUMENT_DELETE, DOCUMENT_QUERY_LIST, DOCUMENT_UPDATE } from 'redux-saga/actions';
import { METHOD } from 'utils/api';
import { watchQuery } from 'utils/sagas';

export function* queryDocumentList() {
  return yield watchQuery(
    DOCUMENT_QUERY_LIST,
    '/document',
    METHOD.GET
  );
}

export function* createDocument() {
  return yield watchQuery(
    DOCUMENT_CREATE,
    '/document',
    METHOD.POST
  );
}

export function* deleteDocument() {
  return yield watchQuery(
    DOCUMENT_DELETE,
    '/document/{id}',
    METHOD.DELETE
  );
}

export function* updateDocument() {
  return yield watchQuery(
    DOCUMENT_UPDATE,
    '/document/{id}',
    METHOD.PUT
  );
}