import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { TableChangeType, TableChangeState, ColumnDescription } from 'react-bootstrap-table-next';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { FaDownload, FaEdit, FaTrashAlt } from 'react-icons/fa';
import * as yup from 'yup';
import { Obj } from 'interface/common';
import { formatDateToDisplay } from 'utils/datetime';
import config from 'config';
import { optionsCategory } from 'global';
import DataTable, { DataTableState } from 'component/DataTable';
import Picker from 'component/Picker';
import { State } from 'redux-saga/reducers';
import { DOCUMENT_CREATE, DOCUMENT_UPDATE, DOCUMENT_DELETE } from 'redux-saga/actions';
import { actionDocument, queryDocumentList } from './actions';
import './styles.scss';

interface DocumentState extends DataTableState {
  showFormModal: boolean;
  showDeleteModal: boolean;
  formData?: DocumentForm;
  submitting: boolean;
  clearError: boolean;
  loading: boolean;
}

export interface DocumentForm {
  id?: number;
  title: string;
  categoryId: number | null;
  document: File | null;
  documentUrl?: string;
}

const blankForm = {
  title: '',
  categoryId: null,
} as DocumentForm;

const schema = yup.object().shape({
  title: yup.string().required('Tiêu đề không được để trống'),
  categoryId: yup.number().typeError('Phải chọn chuyên mục').required('Phải chọn chuyên mục'),
});


const actionFormatter = (onSelectItem: (row: DocumentForm) => void, onDeleteItem: (row: DocumentForm) => void) => ((cell: Obj, row: DocumentForm) => {
  return (
    <div className="d-flex justify-content-center">
      <Button variant="outline-primary" className="mr-2" title="Chỉnh sửa tin" onClick={() => onSelectItem(row)}>
        <FaEdit size={20} />
      </Button>
      <Button variant="outline-danger" className="mr-2" title="Xóa tin" onClick={() => onDeleteItem(row)}>
        <FaTrashAlt size={20} />
      </Button>
      <a target="_blank" rel="noreferrer" href={`${row.documentUrl}`}>
        <Button variant="outline-success" title="Tải tài liệu">
          <FaDownload size={20} />
        </Button>
      </a>
    </div>
  );
})

const columns = (onSelectItem: (row: DocumentForm) => void, onDeleteItem: (row: DocumentForm) => void): ColumnDescription[] => [
  {
    dataField: 'id',
    text: 'No #',
    headerStyle: { width: 60 }
  }, {
    dataField: 'title',
    text: 'Tiêu đề',
    filter: textFilter({
      defaultValue: '',
      placeholder: 'Nhập tiêu đề',
      delay: 0,
    }),
    headerStyle: { width: 200 }
  },
  {
    dataField: "categoryName",
    text: "Chuyên mục",
    filter: selectFilter({
      placeholder: "Tất cả",
      options: optionsCategory,
    }),
    headerStyle: { width: 100 },
  },
  {
    dataField: 'createdAt',
    text: 'Ngày tạo',
    headerStyle: { width: 120 },
    formatter: (row) => {
      return formatDateToDisplay(row, 'dd/MM/yyyy HH:mm:ss', "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as string;
    },
  },
  {
    dataField: 'createdBy',
    text: 'Người tạo',
    headerStyle: { width: 100 }
  },
  {
    dataField: 'action',
    text: '',
    formatter: actionFormatter(onSelectItem, onDeleteItem),
    headerStyle: { width: 170 }
  }
];


const Document = () => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<DocumentState>({
    page: 1,
    data: [],
    totalSize: 0,
    sizePerPage: config.sizePerPage,
    filters: {},
    showFormModal: false,
    showDeleteModal: false,
    submitting: false,
    clearError: false,
    loading: false,
  });

  const ref = React.useRef<{
    sizePerPage?: number;
    page?: number;
    filters?: Obj;
    type?: TableChangeType;
    newState?: TableChangeState<Obj>;
    categoryFilter?: string;
  }>({});

  const formRef = React.useRef<FormikProps<DocumentForm>>();

  const { documentList, documentResult } = useSelector(
    (state: State) => ({
      documentList: state.documentList,
      documentResult: state.documentActionResult,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(queryDocumentList({
      limit: state.sizePerPage,
      page: state.page,
      withCount: true
    }));
  }, []);

  React.useEffect(() => {
    if (documentList && documentList.success && documentList.response) {
      const { response } = documentList;
      setState((prevState) => ({
        ...prevState,
        data: response.data as Obj[],
        totalSize: response.totalCount as number,
        page: response.page as number,
        loading: false
      }));
    }
  }, [documentList]);

  React.useEffect(() => {
    if (documentResult) {
      if (!documentResult.success) {
        setState((prevState) => ({
          ...prevState,
          clearError: false,
          submitting: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showFormModal: false,
          showDeleteModal: false,
          clearError: true,
          submitting: false,
          formData: blankForm
        }));

        formRef.current?.resetForm();

        dispatch(queryDocumentList({
          limit: state.sizePerPage,
          page: state.page,
          withCount: true,
          ...state.filters
        }));
      }

    }
  }, [documentResult]);

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<Obj>,
    query?: boolean
  ) => {
    let categoryName = ref.current?.categoryFilter;
    if (!query) {
      ref.current.type = type;
      ref.current.sizePerPage = newState.sizePerPage;
      ref.current.page = newState.page;
      ref.current.filters = newState.filters;
      ref.current.type = type;
      ref.current.newState = newState;

      if (
        newState.filters["categoryName"] !== undefined ||
        (newState.filters["categoryName"] === undefined &&
          ref.current?.categoryFilter)
        || type !== 'filter'
      ) {
        categoryName = newState.filters["categoryName"]?.filterVal as string;
        if (ref.current?.categoryFilter !== categoryName || type !== 'filter') {
          setState((prevState) => ({
            ...prevState,
            sizePerPage: newState.sizePerPage,
            page: newState.page,
            filters: newState.filters,
            loading: true,
          }));

          const filters: Obj = {};
          Object.keys(newState.filters).forEach((key) => {
            filters[key] = newState.filters[key].filterVal;
          });

          dispatch(
            queryDocumentList({
              limit: newState.sizePerPage,
              page: newState.page,
              withCount: true,
              ...filters,
            })
          );
          ref.current.categoryFilter = categoryName;
        }
      }
    } else {
      if (query) {
        if (ref && ref.current?.newState) {
          setState((prevState) => ({
            ...prevState,
            loading: true,
          }));
          const filters: Obj = {};
          Object.keys(ref.current?.newState?.filters).forEach((key) => {
            filters[key] = ref.current?.newState?.filters[key].filterVal;
          });

          dispatch(
            queryDocumentList({
              limit: ref.current?.newState?.sizePerPage,
              page: ref.current?.newState?.page,
              withCount: true,
              ...filters,
            })
          );
        }
      }
    }
  };


  const handleCloseFormModal = () => {
    setState((prevState) => ({
      ...prevState,
      showFormModal: false,
      clearError: true,
      formData: blankForm
    }));

    formRef.current?.resetForm();
  }

  const handleOpenFormModal = () => {
    setState((prevState) => ({
      ...prevState,
      showFormModal: true,
    }));
  }

  const onFormChange = () => {
    setState((prevState) => ({
      ...prevState,
      clearError: true
    }));
  }

  const handleSubmit = (values: DocumentForm, actions: FormikHelpers<DocumentForm>) => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));

    const form_data = new FormData();

    if (values.id) {
      form_data.append('id', String(values.id));
    }

    form_data.append('title', values.title);
    form_data.append('categoryId', String(values.categoryId));

    if (values.document) {
      form_data.append('document', values.document);
    }

    dispatch(actionDocument(state.formData?.id ? DOCUMENT_UPDATE : DOCUMENT_CREATE, form_data))
  }

  const onSelectItem = (row: DocumentForm) => {
    setState((prevState) => ({
      ...prevState,
      showFormModal: true,
      formData: row
    }));
  }

  const handleCloseDeleteModal = () => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: false
    }));
  }

  const onDeleteDocument = () => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));
    dispatch(actionDocument(DOCUMENT_DELETE, state.formData as unknown as FormData));
  }

  const onDeleteItem = (row: DocumentForm) => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: true,
      formData: row
    }));
  }

  return (
    <Container>
      <div className="page-header d-flex justify-content-between align-items-center">
        <div className="page-title">Văn bản tài liệu</div>
        <Button variant="success" onClick={handleOpenFormModal}>Tạo văn bản tài liệu</Button>
      </div>
      <DataTable
        data={state.data}
        columns={columns(onSelectItem, onDeleteItem)}
        page={state.page}
        totalSize={state.totalSize}
        sizePerPage={state.sizePerPage}
        onTableChange={handleTableChange}
        loading={state.loading}
      />
      <Modal size="sm" show={state.showDeleteModal} onHide={handleCloseDeleteModal} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {state.formData?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          Bạn có chắc chắn muốn xóa tài liệu này không?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Đóng
                </Button>
          <Button type="submit" variant="primary" disabled={state.submitting} onClick={onDeleteDocument}>
            Xóa tài liệu
                </Button>
        </Modal.Footer>
      </Modal>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={state.formData ? state.formData : blankForm}
        enableReinitialize={true}
        validateOnMount
        innerRef={(instance) => { formRef.current = instance! }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          values,
          touched,
          errors,
          isValid
        }) => {
          const onFileUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue("document", event.currentTarget.files ? event.currentTarget.files[0] : null);
          }
          return (
            state.showFormModal && <Form id="documentForm" onChange={onFormChange} onSubmit={handleSubmit} className="form m-auto">
              <Modal show={state.showFormModal} onHide={handleCloseFormModal}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {state.formData?.id != null ? `Cập nhật văn bản #${state.formData?.id}` : 'Tạo văn bản mới'}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-body">
                    <Form.Group controlId="title">
                      <Form.Label className="d-block">Tiêu đề</Form.Label>
                      <Form.Control
                        name="title"
                        type="text"
                        placeholder="Nhập tiêu đề"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.title && !errors.title}
                        value={values.title}
                        className={touched.title && errors.title ? "error" : undefined}
                      />
                      {touched.title && errors.title && (
                        <p className="error">{errors.title}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="categoryId">
                      <Form.Label className="d-block">Chuyên mục</Form.Label>
                      <Picker
                        value={values.categoryId!}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        options={optionsCategory}
                        placeholder="Chọn chuyên mục"
                        name="categoryId"
                      />
                      {touched.categoryId && errors.categoryId && (
                        <p className="error">{errors.categoryId}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="document">
                      <Form.Label className="d-block">File tài liệu</Form.Label>
                      <Form.Control
                        name="document"
                        type="file"
                        value={undefined}
                        placeholder="Chọn file tài liệu"
                        onChange={onFileUpdate}
                        onBlur={handleBlur}
                      />
                      {!state.clearError && documentResult && !documentResult.success && documentResult.error && documentResult.error.errors &&
                        documentResult.error.errors.findIndex((value) => value.field === 'document') >= 0 &&
                        (
                          documentResult.error.errors.find((value) => value.field === 'document')?.messages.map((message, index) =>
                            (
                              <p className="error" key={index}>{message}<br /></p>
                            ))
                        )}

                    </Form.Group>
                    {state.formData?.id &&
                      <Form.Group>
                        <Form.Label className="d-block">Tải file văn bản</Form.Label>
                        <a target="_blank" rel="noreferrer" href={state.formData.documentUrl}>Tải về</a>
                      </Form.Group>
                    }
                    <Form.Group>
                      {!state.clearError && documentResult && !documentResult.success && documentResult.error && (
                        <p className="error">{documentResult.error.message}</p>
                      )}
                    </Form.Group>
                  </div>

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseFormModal}>
                    Đóng
                </Button>
                  <Button type="submit" form="documentForm" variant="primary" disabled={!isValid || state.submitting}>
                    Lưu văn bản
                </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )
        }}
      </Formik>
    </Container >
  );
}

export default Document;