export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_VERIFY_TOKEN = 'AUTH_VERIFY_TOKEN';

export const USER_QUERY_LIST = 'USER_QUERY_LIST';
export const USER_UPDATE = 'USER_UPDATE';

export const NEWS_QUERY_LIST = 'NEWS_QUERY_LIST';
export const NEWS_CREATE = 'NEWS_CREATE';
export const NEWS_UPDATE = 'NEWS_UPDATE';
export const NEWS_LOAD_ITEM = 'NEWS_LOAD_ITEM';
export const NEWS_DELETE = 'NEWS_DELETE';

export const DOCUMENT_QUERY_LIST = 'DOCUMENT_QUERY_LIST';
export const DOCUMENT_CREATE = 'DOCUMENT_CREATE';
export const DOCUMENT_UPDATE = 'DOCUMENT_UPDATE';
export const DOCUMENT_DELETE = 'DOCUMENT_DELETE';

export const VIDEO_QUERY_LIST = 'VIDEO_QUERY_LIST';
export const VIDEO_CREATE = 'VIDEO_CREATE';
export const VIDEO_UPDATE = 'VIDEO_UPDATE';
export const VIDEO_DELETE = 'VIDEO_DELETE';

export const FILE_UPLOAD_IMAGE = 'FILE_UPLOAD_IMAGE';

export const MARKET_PRICE_QUERY_LIST = 'MARKET_PRICE_QUERY_LIST';
export const MARKET_PRICE_UPDATE = 'MARKET_PRICE_UPDATE';
export const MARKET_PRICE_CREATE = 'MARKET_PRICE_CREATE';
export const MARKET_PRICE_DELETE = 'MARKET_PRICE_DELETE';
export const MARKET_PRICE_LOAD_ITEM = 'MARKET_PRICE_LOAD_ITEM';

export const PROFILE_CHANGE_PASSWORD = 'PROFILE_CHANGE_PASSWORD';

export const ADMIN_UPDATE = 'ADMIN_UPDATE';
export const ADMIN_CREATE = 'ADMIN_CREATE';

export const FARMER_QUERY_LIST = 'FARMER_QUERY_LIST';

export const EXPERT_CREATE = 'EXPERT_CREATE';

export const FARMER_EXPORT_LIST = 'FARMER_EXPORT_LIST';

export const QUESTION_QUERY_LIST = 'QUESTION_QUERY_LIST';
export const QUESTION_DELETE = 'QUESTION_DELETE';

export const ANSWER_DELETE = 'ANSWER_DELETE';
