import { FARMER_QUERY_LIST, FARMER_EXPORT_LIST } from 'redux-saga/actions';
import { METHOD } from 'utils/api';
import { watchQuery } from 'utils/sagas';

export function* queryFarmerList() {
  return yield watchQuery(
    FARMER_QUERY_LIST,
    '/farmer',
    METHOD.GET
  );
}

export function* exportFarmerList() {
  return yield watchQuery(
    FARMER_EXPORT_LIST,
    '/farmer/export/excel',
    METHOD.GET,
    'latest',
    undefined,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  );
}
