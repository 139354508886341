import { all } from "redux-saga/effects";
import { login, verifyToken } from "./saga/Authentication";
import { createDocument, deleteDocument, queryDocumentList, updateDocument } from "./saga/Document";
import { uploadImage } from "./saga/File";
import { createNews, deleteNews, loadNews, queryNewsList, updateNews } from "./saga/News";
import { createVideo, deleteVideo, queryVideoList, updateVideo } from "./saga/Video";
import { createMarketPrice, deleteMarketPrice, loadMarketPrice, queryMarketPriceList, updateMarketPrice } from './saga/MarketPrice';
import { queryUserList, changePassword, updateUser, createExpert } from "./saga/User";
import { createAdmin, updateAdmin } from "./saga/Admin";
import { queryFarmerList, exportFarmerList } from "./saga/Farmer";
import { deleteQuestion, queryQuestionList } from "./saga/Question";
import { deleteAnswer } from "./saga/Answer";

const sagas = function* () {
  yield all([
    //Authentication
    login(),
    verifyToken(),

    //User
    queryUserList(),
    changePassword(),
    updateUser(),
    
    //Admin
    updateAdmin(),
    createAdmin(),

    //News
    queryNewsList(),
    createNews(),
    updateNews(),
    loadNews(),
    deleteNews(),

    //Document
    queryDocumentList(),
    createDocument(),
    updateDocument(),
    deleteDocument(),

    //Video
    queryVideoList(),
    createVideo(),
    updateVideo(),
    deleteVideo(),

    //File
    uploadImage(),

    //market price
    queryMarketPriceList(),
    createMarketPrice(),
    loadMarketPrice(),
    updateMarketPrice(),
    deleteMarketPrice(),

    //Question
    queryQuestionList(),
    deleteQuestion(),

    //Answer
    deleteAnswer(),

    queryFarmerList(),

    createExpert(),

    exportFarmerList(),
  ])
}

export default sagas;