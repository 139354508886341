import { watchQuery } from "utils/sagas";
import { ADMIN_CREATE, ADMIN_UPDATE } from "redux-saga/actions";
import { METHOD } from "utils/api";

export function* updateAdmin() {
    return yield watchQuery(
        ADMIN_UPDATE,
        '/admin/{id}',
        METHOD.PUT
    );
}

export function* createAdmin() {
    return yield watchQuery(
        ADMIN_CREATE,
        '/admin',
        METHOD.POST
    );
}