import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Container, Modal } from 'react-bootstrap';
import { TableChangeType, TableChangeState, ColumnDescription } from 'react-bootstrap-table-next';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { FormikProps } from 'formik';
import { FaEye, FaTrashAlt } from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";
import { NotusAttribute, Obj } from 'interface/common';
import { formatDateToDisplay } from 'utils/datetime';
import { convert } from 'utils/notus';
import config from 'config';
import { optionsCategory } from 'global';
import DataTable, { DataTableState } from 'component/DataTable';
import Answers from 'component/Answer';
import { State } from 'redux-saga/reducers';
import { QUESTION_DELETE } from 'redux-saga/actions';
import { actionQuestion, queryQuestionList } from './actions';
import './styles.scss';

interface QuestionState extends DataTableState {
  showFormModal: boolean;
  showDeleteModal: boolean;
  formData?: QuestionForm;
  submitting: boolean;
  clearError: boolean;
  loading: boolean;
  showModalAnswers: boolean;
}

export interface QuestionForm {
  id?: number;
  title: string;
  categoryId: number | null;
  question: NotusAttribute[] | null;
  questionUrl?: string;
  answers: Obj[];
}

const blankForm = {
  title: "",
  categoryId: null,
} as QuestionForm;



const actionFormatter = (onSelectItem: (row: QuestionForm) => void, onDeleteItem: (row: QuestionForm) => void) => ((cell: Obj, row: QuestionForm) => {
  return (
    <div className="d-flex justify-content-center">
      <Button
        variant="outline-danger"
        className="mr-2"
        title="Xóa tin"
        onClick={() => onDeleteItem(row)}
      >
        <FaTrashAlt size={20} />
      </Button>
      <Button
        variant="outline-success"
        title="Xem câu trả lời"
        onClick={() => onSelectItem(row)}
      >
        <FaEye size={20} />
      </Button>
    </div>
  );
})

const columns = (
  onSelectItem: (row: QuestionForm) => void,
  onDeleteItem: (row: QuestionForm) => void
): ColumnDescription[] => [
    {
      dataField: "id",
      text: "No #",
      headerStyle: { width: 60 },
    },
    {
      dataField: "title",
      text: "Tiêu đề",
      filter: textFilter({
        defaultValue: "",
        placeholder: "Nhập tiêu đề",
        delay: 0,
      }),
      headerStyle: { width: 200 },
    },
    {
      dataField: "shortDescription",
      text: "Nội dung",
      headerStyle: { width: 200 },
    },
    {
      dataField: "categoryName",
      text: "Chuyên mục",
      filter: selectFilter({
        placeholder: "Tất cả",
        options: optionsCategory,
      }),
      headerStyle: { width: 100 },
    },
    {
      dataField: "createdAt",
      text: "Ngày tạo",
      headerStyle: { width: 120 },
      formatter: (row) => {
        return formatDateToDisplay(
          row,
          "dd/MM/yyyy HH:mm:ss",
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
        ) as string;
      },
    },
    {
      dataField: "createdBy",
      text: "Người tạo",
      headerStyle: { width: 100 },
    },
    {
      dataField: "action",
      text: "",
      formatter: actionFormatter(onSelectItem, onDeleteItem),
      headerStyle: { width: 170 },
    },
  ];


const Question = () => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<QuestionState>({
    page: 1,
    data: [],
    totalSize: 0,
    sizePerPage: config.sizePerPage,
    filters: {},
    showFormModal: false,
    showDeleteModal: false,
    submitting: false,
    clearError: false,
    loading: false,
    showModalAnswers: false,
  });

  const ref = React.useRef<{
    sizePerPage?: number;
    page?: number;
    filters?: Obj;
    type?: TableChangeType;
    newState?: TableChangeState<Obj>;
    categoryFilter?: string;
  }>({});

  const formRef = React.useRef<FormikProps<QuestionForm>>();

  const { questionList, questionResult, answerResult } = useSelector(
    (state: State) => ({
      questionList: state.questionList,
      questionResult: state.questionActionResult,
      answerResult: state.answerResult,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(queryQuestionList({
      limit: state.sizePerPage,
      page: state.page,
      withCount: true
    }));
  }, []);

  React.useEffect(() => {
    if (questionList && questionList.success && questionList.response) {
      const { response } = questionList;
      const formData = (response.data as Obj[]).filter(
        (item) => item.id === state.formData?.id
      );
      setState((prevState) => ({
        ...prevState,
        data: response.data as Obj[],
        totalSize: response.totalCount as number,
        page: response.page as number,
        loading: false,
        formData: formData? formData[0] as unknown as QuestionForm: blankForm,
      }));
    }
  }, [questionList]);

  React.useEffect(() => {
    if (answerResult && answerResult.success && answerResult.response) {
      dispatch(
        queryQuestionList({
          limit: state.sizePerPage,
          page: state.page,
          withCount: true,
        })
      );
    }
  }, [answerResult]);


  React.useEffect(() => {
    if (questionResult) {
      if (!questionResult.success) {
        setState((prevState) => ({
          ...prevState,
          clearError: false,
          submitting: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showFormModal: false,
          showDeleteModal: false,
          clearError: true,
          submitting: false,
          showModalAnswers: false,
          formData: blankForm
        }));

        formRef.current?.resetForm();

        dispatch(queryQuestionList({
          limit: state.sizePerPage,
          page: state.page,
          withCount: true,
          ...state.filters
        }));
      }

    }
  }, [questionResult]);

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<Obj>,
    query?: boolean
  ) => {
    let categoryName = ref.current?.categoryFilter;
    if (!query) {
      ref.current.type = type;
      ref.current.sizePerPage = newState.sizePerPage;
      ref.current.page = newState.page;
      ref.current.filters = newState.filters;
      ref.current.type = type;
      ref.current.newState = newState;

      if (
        newState.filters["categoryName"] !== undefined ||
        (newState.filters["categoryName"] === undefined &&
          ref.current?.categoryFilter)
        || type !== 'filter'
      ) {
        categoryName = newState.filters["categoryName"]?.filterVal as string;
        if (ref.current?.categoryFilter !== categoryName || type !== 'filter') {
          setState((prevState) => ({
            ...prevState,
            sizePerPage: newState.sizePerPage,
            page: newState.page,
            filters: newState.filters,
            loading: true,
          }));

          const filters: Obj = {};
          Object.keys(newState.filters).forEach((key) => {
            filters[key] = newState.filters[key].filterVal;
          });

          dispatch(
            queryQuestionList({
              limit: newState.sizePerPage,
              page: newState.page,
              withCount: true,
              ...filters,
            })
          );
          ref.current.categoryFilter = categoryName;
        }
      }
    } else {
      if (query) {
        if (ref && ref.current?.newState) {
          setState((prevState) => ({
            ...prevState,
            loading: true,
          }));
          const filters: Obj = {};
          Object.keys(ref.current?.newState?.filters).forEach((key) => {
            filters[key] = ref.current?.newState?.filters[key].filterVal;
          });

          dispatch(
            queryQuestionList({
              limit: ref.current?.newState?.sizePerPage,
              page: ref.current?.newState?.page,
              withCount: true,
              ...filters,
            })
          );
        }
      }
    }
  };

  const handleCloseAnswersModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModalAnswers: false,
      clearError: true,
      formData: blankForm,
    }));

    formRef.current?.resetForm();
  };

  const onSelectItem = (row: QuestionForm) => {
    setState((prevState) => ({
      ...prevState,
      showModalAnswers: true,
      formData: row,
    }));
  }

  const handleCloseDeleteModal = () => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: false
    }));
  }

  const onDeleteQuestion = () => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));
    dispatch(actionQuestion(QUESTION_DELETE, state.formData as unknown as FormData));
  }

  const onDeleteItem = (row: QuestionForm) => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: true,
      formData: row
    }));
  }


  return (
    <Container>
      <div className="page-header d-flex justify-content-between align-items-center">
        <div className="page-title">Danh sách câu hỏi</div>
      </div>

      {state.showModalAnswers && (
        <Modal
          show={state.showModalAnswers}
          onHide={handleCloseAnswersModal}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Danh sách câu trả lời của câu hỏi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="border-bottom">
              <h3>{state.formData?.title}</h3>
              {ReactHtmlParser(
                convert(state.formData?.question as NotusAttribute[])
              )}
            </div>
            {(state.formData?.answers as Obj[]).length > 0 ? (
              <div className="ml-5">
                {(state.formData?.answers as Obj[]).map((item, key) => (
                  <Answers key={key} answer={item} id={state.formData?.id as number} />
                ))}
              </div>
            ) : (
                <div className="d-flex justify-content-center text-danger mt-2">
                  Hiện chưa có câu trả lời nào cho câu hỏi.
                </div>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAnswersModal}>
              Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <DataTable
        data={state.data}
        columns={columns(onSelectItem, onDeleteItem)}
        page={state.page}
        totalSize={state.totalSize}
        sizePerPage={state.sizePerPage}
        onTableChange={handleTableChange}
        loading={state.loading}
      />
      <Modal
        size="sm"
        show={state.showDeleteModal}
        onHide={handleCloseDeleteModal}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{state.formData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          Bạn có chắc chắn muốn xóa câu hỏi này không?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Đóng
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={state.submitting}
            onClick={onDeleteQuestion}
          >
            Xóa câu hỏi
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Question;