import { createReducer } from "utils/redux";

export const DOCUMENT_QUERY_LIST_SUCCESS = 'DOCUMENT_QUERY_LIST_SUCCESS';
export const DOCUMENT_QUERY_LIST_FAILED = 'DOCUMENT_QUERY_LIST_FAILED';

export const DOCUMENT_ACTION_SUCCESS = 'DOCUMENT_ACTION_SUCCESS';
export const DOCUMENT_ACTION_FAILED = 'DOCUMENT_ACTION_FAILED';
export const DOCUMENT_ACTION_CLEAR = 'DOCUMENT_ACTION_CLEAR';

export const DocumentList = createReducer(
  DOCUMENT_QUERY_LIST_SUCCESS,
  DOCUMENT_QUERY_LIST_FAILED
);

export const DocumentActionResult = createReducer(
  DOCUMENT_ACTION_SUCCESS,
  DOCUMENT_ACTION_FAILED,
  DOCUMENT_ACTION_CLEAR
);
