import { Obj } from "interface/common";
import { USER_QUERY_LIST } from "redux-saga/actions";
import {
    USER_ACTION_FAILED,
    USER_ACTION_SUCCESS,
    USER_QUERY_LIST_FAILED,
    USER_QUERY_LIST_SUCCESS
} from "./reducers";

export const queryUserList = (payload: Obj) => ({
    type: USER_QUERY_LIST,
    response: {
        success: {
            type: USER_QUERY_LIST_SUCCESS,
        },
        failure: {
            type: USER_QUERY_LIST_FAILED,
        },
    },
    payload
});

export const actionUser = (type: string, payload: Obj) => ({
    type,
    response: {
        success: {
            type: USER_ACTION_SUCCESS,
        },
        failure: {
            type: USER_ACTION_FAILED,
        },
    },
    payload
});