import { FileUploadResult } from 'component/ContentEditor/reducers';
import { combineReducers, AnyAction } from 'redux';
import { AuthState } from 'router/reducers';
import { AdminActionResult } from 'screen/Admin/reducers';
import { DocumentActionResult, DocumentList } from 'screen/Document/reducers';
import { ExportFarmer, FarmerList } from 'screen/Farmer/reducers';
import { LoginResult } from 'screen/Login/reducers';
import { MarketPriceList } from 'screen/MarketPrice/reducers';
import {
  MarketPriceActionResult,
  MarketPriceItem
} from 'screen/MarketPriceForm/reducers';
import { NewsList } from 'screen/News/reducers';
import { NewsActionResult, NewsItem } from 'screen/NewsForm/reducers';
import { ChangePasswordResult } from 'screen/Profile/reducers';
import { UserActionResult, UserList } from 'screen/User/reducers';
import { VideoList, VideoActionResult } from 'screen/Video/reducers';
import { QuestionActionResult, QuestionList } from 'screen/Question/reducers';
import { AnswerActionResult } from 'component/Answer/reducers';

export const appReducer = combineReducers({
  //Authentication
  loginResult: LoginResult,
  authState: AuthState,

  //User
  userList: UserList,
  userActionResult: UserActionResult,
  changePasswordResult: ChangePasswordResult,

  //News
  newsList: NewsList,
  newsActionResult: NewsActionResult,
  newsItem: NewsItem,

  //Document
  documentList: DocumentList,
  documentActionResult: DocumentActionResult,

  //Video
  videoList: VideoList,
  videoActionResult: VideoActionResult,

  //File
  fileUploadResult: FileUploadResult,

  //Market Price
  marketPriceList: MarketPriceList,
  marketPriceActionResult: MarketPriceActionResult,
  marketPriceItem: MarketPriceItem,

  //admin
  adminActionResult: AdminActionResult,

  //farmer
  farmerList: FarmerList,
  exportFarmer: ExportFarmer,

  //Question
  questionList: QuestionList,
  questionActionResult: QuestionActionResult,

  //Answer
  answerResult: AnswerActionResult,

});

export type State = ReturnType<typeof appReducer>;

export const rootReducer = (state: State, action: AnyAction) => {
  return appReducer(state, action);
}