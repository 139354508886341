import { Obj } from "interface/common";
import { ADMIN_ACTION_FAILED, ADMIN_ACTION_SUCCESS } from "./reducers";

export const actionAdmin = (type: string, payload: Obj) => ({
    type,
    response: {
        success: {
            type: ADMIN_ACTION_SUCCESS,
        },
        failure: {
            type: ADMIN_ACTION_FAILED,
        },
    },
    payload
});