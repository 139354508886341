import { createReducer } from "utils/redux";

export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED';
export const FILE_UPLOAD_CLEAR = 'FILE_UPLOAD_CLEAR';

export const FileUploadResult = createReducer(
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILED,
  FILE_UPLOAD_CLEAR
);