import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { State } from 'redux-saga/reducers';
import { LOGIN_CLEAR } from './reducers';
import { login } from './actions';
import './styles.scss';

const schema = yup.object().shape({
  username: yup.string().required('Tài khoản đăng nhập không được để trống'),
  password: yup.string().required('Mật khẩu không được để trống'),
});

export interface LoginForm {
  username: string;
  password: string;
}

const Login = () => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    submitting: false,
    clearError: false,
  });

  const loginResult = useSelector(
    (state: State) => state.loginResult,
    shallowEqual
  );

  React.useEffect(() => {
    return () => {
      dispatch({
        type: LOGIN_CLEAR
      });
    }
  }, []);

  React.useEffect(() => {
    if (loginResult && !loginResult.success) {
      setState((prevState) => ({
        ...prevState,
        clearError: false,
        submitting: false,
      }));
    }
  }, [loginResult]);

  const onFormChange = () => {
    setState((prevState) => ({
      ...prevState,
      clearError: true
    }));
  }

  const handleSubmit = (values: LoginForm, actions: FormikHelpers<LoginForm>) => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));
    dispatch(login(values));
  }
  return (
    <div className="d-flex">
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          username: '',
          password: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isValid
        }) => (
          <Form onChange={onFormChange} onSubmit={handleSubmit} className="form form-login m-auto">
            <div className="form-header">
              Đăng nhập
            </div>
            <div className="form-body">
              <Form.Group controlId="username">
                <Form.Label className="d-block">Tài khoản đăng nhập</Form.Label>
                <Form.Control
                  name="username"
                  type="text"
                  placeholder="Nhập tài khoản đăng nhập"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.username && !errors.username}
                  value={values.username}
                  className={touched.username && errors.username ? "error" : undefined}
                />
                {touched.username && errors.username && (
                  <p className="error">{errors.username}</p>
                )}
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label className="d-block">Mật khẩu</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Nhập mật khẩu"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.password && !errors.password}
                  value={values.password}
                  className={touched.password && errors.password ? "error" : undefined}
                />
                {touched.password && errors.password && (
                  <p className="error">{errors.password}</p>
                )}
              </Form.Group>
              <Form.Group className="text-center">
                <Button type="submit" variant="primary" className="btn-login w-100 py-2" disabled={!isValid || state.submitting}>Đăng nhập</Button>
              </Form.Group>
              <Form.Group>
                {!state.clearError && loginResult && !loginResult.success && loginResult.error && (
                  <p className="error">{loginResult.error.message}</p>
                )}
              </Form.Group>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Login;