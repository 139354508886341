import { createReducer } from 'utils/redux';

export const NEWS_ACTION_FAILED = 'NEWS_ACTION_FAILED';
export const NEWS_ACTION_SUCCESS = 'NEWS_ACTION_SUCCESS';
export const NEWS_ACTION_CLEAR = 'NEWS_ACTION_CLEAR';

export const NEWS_LOAD_ITEM_SUCCESS = 'NEWS_LOAD_ITEM_SUCCESS';
export const NEWS_LOAD_ITEM_FAILED = 'NEWS_LOAD_ITEM_FAILED';
export const NEWS_LOAD_ITEM_CLEAR = 'NEWS_LOAD_ITEM_CLEAR';

export const NewsActionResult = createReducer(
  NEWS_ACTION_SUCCESS,
  NEWS_ACTION_FAILED,
  NEWS_ACTION_CLEAR
);

export const NewsItem = createReducer(
  NEWS_LOAD_ITEM_SUCCESS,
  NEWS_LOAD_ITEM_FAILED,
  NEWS_LOAD_ITEM_CLEAR
);