import { AUTH_LOGIN } from 'redux-saga/actions';
import { LoginForm } from '.';
import { LOGIN_FAILED, LOGIN_SUCCESS } from './reducers';

export const login = (payload: LoginForm) => ({
  type: AUTH_LOGIN,
  response: {
    success: {
      type: LOGIN_SUCCESS,
    },
    failure: {
      type: LOGIN_FAILED,
    },
  },
  payload
});