import { createReducer } from "utils/redux";


export const ANSWER_ACTION_SUCCESS = 'ANSWER_ACTION_SUCCESS';
export const ANSWER_ACTION_FAILED = 'ANSWER_ACTION_FAILED';
export const ANSWER_ACTION_CLEAR = 'ANSWER_ACTION_CLEAR';

export const AnswerActionResult = createReducer(
  ANSWER_ACTION_SUCCESS,
  ANSWER_ACTION_FAILED,
  ANSWER_ACTION_CLEAR
);
