import download from 'downloadjs';
import config from 'config';
import { Obj } from 'interface/common';
import { CustomError, Global } from 'global';
import { string } from 'yup/lib/locale';
import store from 'redux-saga/store';
import { FARMER_EXPORT_LIST_SUCCESS } from 'screen/Farmer/reducers';

export enum METHOD {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

const replacePlaceholder = (s: string, data: Obj) => {
  const parts = s.split(/{(.*?)}/g).map((v) => {
    const replaced = v.replace(/{/g, '');
    if (data instanceof FormData) {
      return data.get(replaced) || replaced;
    }
    return data[replaced] || replaced;
  });

  return parts.join('');
};

const handleRESTError = async (response: Response) => {
  if (response.status !== 200) {
    const res = await response.json();
    throw new CustomError(
      res.type,
      response.status,
      res.message ? res.message : response.statusText,
      res.errors
    );
  }
  return response;
};

const parseJSONFromREST = async (response: Response) => {
  return await response.json();
};

const handleFileDownload = async (response: Response) => {
  let fileName: string | undefined = undefined;
  if (response.headers.get('Content-Disposition')) {
    fileName = response.headers.get('Content-Disposition')!.split("filename=")[1];
  }

  const blob = await response.blob();
  download(blob, fileName?.substring(1, fileName.length - 1));
  store.dispatch({
    type: FARMER_EXPORT_LIST_SUCCESS,
    payload: { export: true }
  });
}

export const query = async (uri: string, method: METHOD, params: object = {}, contentType?: string) => {
  let parsedUri = `${config.endpoint}${uri}${method === METHOD.GET ? `?${new URLSearchParams(params as Record<string, string>)}` : ''
    }`;
  parsedUri = replacePlaceholder(parsedUri, params as Obj);
  return fetch(parsedUri, {
    method,
    headers: {
      Authorization: `Bearer ${Global.jwtToken}`,
      "cms": "true",
      ...(!(params instanceof FormData) && { 'Content-Type': contentType ? contentType : 'application/json; charset=UTF-8' }),
    },
    ...(method !== METHOD.GET && { body: !(params instanceof FormData) ? JSON.stringify(params) : params }),
  })
    .then(handleRESTError)
    .then(contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? handleFileDownload : parseJSONFromREST);
};
