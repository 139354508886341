import { Obj } from 'interface/common';
import { MARKET_PRICE_QUERY_LIST } from 'redux-saga/actions';
import { MARKET_PRICE_QUERY_LIST_FAILED, MARKET_PRICE_QUERY_LIST_SUCCESS } from './reducers';


export const queryMarketPriceList = (payload: Obj) => ({
  type: MARKET_PRICE_QUERY_LIST,
  response: {
    success: {
      type: MARKET_PRICE_QUERY_LIST_SUCCESS,
    },
    failure: {
      type: MARKET_PRICE_QUERY_LIST_FAILED,
    },
  },
  payload
});