import { CustomError } from 'global';
import { Obj, Action } from 'interface/common';

export const createReducer = (success: string, failure: string, clear?: string) => {
  return (state: {
    success: boolean;
    response?: Obj;
    request?: Obj;
    error?: CustomError;
  } | null = null, action: Action<Obj>) => {
    switch (action.type) {
      case success:
        return { ...action.payload, success: true };
      case failure:
        return { ...action.payload, success: false };
      case clear:
        return null;
      default:
        return state;
    }
  };
};