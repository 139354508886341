import { AUTH_LOGIN, AUTH_VERIFY_TOKEN } from 'redux-saga/actions';
import { METHOD } from 'utils/api';
import { watchQuery } from 'utils/sagas';

export function* login() {
  return yield watchQuery(
    AUTH_LOGIN,
    '/user/login',
    METHOD.POST
  );
}

export function* verifyToken() {
  return yield watchQuery(
    AUTH_VERIFY_TOKEN,
    '/user/verify',
    METHOD.GET
  );
}