import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Global } from 'global';
import { getKey } from 'utils/localStorage';
import Login from 'screen/Login';
import Document from 'screen/Document';
import News from 'screen/News';
import User from 'screen/User'
import NewsForm from 'screen/NewsForm';
import MarketPrice from 'screen/MarketPrice';
import MarketPriceForm from 'screen/MarketPriceForm';
import Video from 'screen/Video';
import Admin from 'screen/Admin';
import Profile from 'screen/Profile';
import AppTitle from 'component/AppTitle';
import Menu from 'component/Menu';
import { State } from 'redux-saga/reducers';
import { verifyToken } from './actions';
import Farmer from 'screen/Farmer';
import Question from 'screen/Question';

Global.jwtToken = getKey<string>('token')!;

const Router = () => {
  const dispatch = useDispatch();
  const authState = useSelector(
    (state: State) => state.authState,
    shallowEqual
  );

  React.useEffect(() => {
    if (Global.jwtToken) {
      dispatch(verifyToken());
    }
  }, []);
  const authenticated = Global.jwtToken != null && authState;

  return (
    (Global.jwtToken == null || authState != null) ? <div className="app">
      <BrowserRouter>
        <header className="app-header">
          <Menu />
        </header>
        <AppTitle />
        <Switch>
          <Route exact path='/login' render={() => !authenticated ? <Login /> : <Redirect to="/news" />} />
          <Route exact path='/news' render={() => authenticated ? <News /> : <Redirect to="/login" />} />
          <Route exact path='/news/create' render={() => authenticated ? <NewsForm /> : <Redirect to="/login" />} />
          <Route exact path="/news/:id" render={() => authenticated ? <NewsForm /> : <Redirect to="/login" />} />
          <Route exact path='/document' render={() => authenticated ? <Document /> : <Redirect to="/login" />} />
          <Route exact path='/video' render={() => authenticated ? <Video /> : <Redirect to="/login" />} />
          <Route exact path='/market-price' render={() => authenticated ? <MarketPrice /> : <Redirect to="/login" />} />
          <Route exact path='/market-price/create' render={() => authenticated ? <MarketPriceForm /> : <Redirect to="/login" />} />
          <Route exact path='/market-price/:id' render={() => authenticated ? <MarketPriceForm /> : <Redirect to="/login" />} />
          <Route exact path='/user' render={() => authenticated ? <User /> : <Redirect to="/login" />} />
          <Route exact path='/farmer' render={() => authenticated ? <Farmer /> : <Redirect to="/login" />} />
          <Route exact path='/admin' render={() => authenticated ? <Admin /> : <Redirect to="/login" />} />
          <Route exact path='/profile' render={() => authenticated ? <Profile /> : <Redirect to="/login" />} />
          <Route exact path='/question' render={() => authenticated ? <Question /> : <Redirect to="/login" />} />
          <Route>
            <Redirect to="/news" />
          </Route>
        </Switch>
      </BrowserRouter>
    </div> : null
  );
}

export default Router;