import { createReducer } from "utils/redux";

export const FARMER_QUERY_LIST_SUCCESS = 'FARMER_QUERY_LIST_SUCCESS';
export const FARMER_QUERY_LIST_FAILED = 'FARMER_QUERY_LIST_FAILED';

export const FarmerList = createReducer(
  FARMER_QUERY_LIST_SUCCESS,
  FARMER_QUERY_LIST_FAILED
);

export const FARMER_EXPORT_LIST_SUCCESS = 'FARMER_EXPORT_LIST_SUCCESS';
export const FARMER_EXPORT_LIST_FAILED = 'FARMER_EXPORT_LIST_FAILED';
export const FARMER_EXPORT_LIST_CLEAR = 'FARMER_EXPORT_LIST_CLEAR';

export const ExportFarmer = createReducer(
  FARMER_EXPORT_LIST_SUCCESS,
  FARMER_EXPORT_LIST_FAILED,
  FARMER_EXPORT_LIST_CLEAR
);