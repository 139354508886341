import { Request, Obj } from 'interface/common';
import { call, put, takeLatest, takeEvery, throttle, debounce } from 'redux-saga/effects';
import { METHOD, query as queryAPI } from './api';

const query = (
  uri: string,
  method: METHOD,
  params?: Obj,
  contentType?: string
) => {
  return queryAPI(uri, method, params, contentType);
};

function* doQuery(
  uri: string,
  method: METHOD,
  contentType: string | undefined,
  request: Request<Obj>
) {
  try {
    const response = yield call(query, uri, method, request.payload, contentType);

    if (request.response && request.response.success) {
      yield put({
        type: request.response.success.type,
        payload: {
          response,
          request: request.payload,
        },
      });

    }
  } catch (error) {
    if (request.response && request.response.failure) {
      yield put({
        type: request.response.failure.type,
        payload: {
          request: request.payload,
          error
        },
      });
    }
  }
}

export function* watchQuery(
  pattern: string,
  uri: string,
  method: METHOD,
  mode?: 'latest' | 'every' | 'throttle' | 'debounce',
  ms?: number,
  contentType?: string
): Generator {
  if (mode == null) {
    if (method !== METHOD.GET) {
      mode = 'latest';
    } else {
      mode = 'every';
    }
  }

  switch (mode) {
    case 'latest':
      yield takeLatest(
        pattern,
        doQuery,
        uri,
        method,
        contentType
      );
      break;
    case 'every':
      yield takeEvery(
        pattern,
        doQuery,
        uri,
        method,
        contentType
      );
      break;
    case 'throttle':
      yield throttle(
        ms!,
        pattern,
        doQuery,
        uri,
        method,
        contentType
      );
      break;
    case 'debounce':
      yield debounce(
        ms!,
        pattern,
        doQuery,
        uri,
        method,
        contentType
      );
      break;
    default:
      break;
  }
}