import {
    MARKET_PRICE_CREATE,
    MARKET_PRICE_DELETE,
    MARKET_PRICE_LOAD_ITEM,
    MARKET_PRICE_QUERY_LIST,
    MARKET_PRICE_UPDATE
} from "redux-saga/actions";
import { METHOD } from "utils/api";
import { watchQuery } from "utils/sagas";

export function* queryMarketPriceList() {
    return yield watchQuery(
        MARKET_PRICE_QUERY_LIST,
        '/market',
        METHOD.GET
    );
}

export function* createMarketPrice() {
    return yield watchQuery(
        MARKET_PRICE_CREATE,
        '/market',
        METHOD.POST
    );
}

export function* updateMarketPrice() {
    return yield watchQuery(
        MARKET_PRICE_UPDATE,
        '/market/{id}',
        METHOD.PUT
    );
}

export function* deleteMarketPrice() {
    return yield watchQuery(
        MARKET_PRICE_DELETE,
        '/market/{id}',
        METHOD.DELETE
    );
}

export function* loadMarketPrice() {
    return yield watchQuery(
        MARKET_PRICE_LOAD_ITEM,
        '/market/{id}',
        METHOD.GET
    );
}