import * as React from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Container, Modal, Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { TableChangeType, TableChangeState, ColumnDescription } from 'react-bootstrap-table-next';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import { Obj } from 'interface/common';
import { formatDateToDisplay } from 'utils/datetime';
import config from 'config';
import { optionsCategory } from 'global';
import DataTable, { DataTableState } from 'component/DataTable';
import { NewsForm } from 'screen/NewsForm';
import { State } from 'redux-saga/reducers';
import { actionNews } from 'screen/NewsForm/actions';
import { queryNewsList } from './actions';
import { NEWS_DELETE } from 'redux-saga/actions';
import './styles.scss';

interface NewsState extends DataTableState {
  loading: boolean;
  formData?: NewsForm;
  clearError: boolean;
  submitting: boolean;
  showDeleteModal: boolean;
  showViewModal: boolean;
  type?: TableChangeType;
  newState?: TableChangeState<Obj>;
}

const actionFormatter = (onDeleteItem: (row: NewsForm) => void, onSelectItem: (row: NewsForm) => void) => (cell: Obj, row: NewsForm) => {
  return (
    <div className="d-flex justify-content-center">
      <Link to={`/news/${row.id}`}>
        <Button
          variant="outline-primary"
          className="mr-2"
          title="Chỉnh sửa tin"
        >
          <FaEdit size={20} />
        </Button>
      </Link>
      <Button
        variant="outline-danger"
        className="mr-2"
        title="Xóa tin"
        onClick={() => onDeleteItem(row)}
      >
        <FaTrashAlt size={20} />
      </Button>
      <Button
        variant="outline-success"
        className="mr-2"
        title="Xem tin tức"
        onClick={() => onSelectItem(row)}
      >
        <FaEye size={20} />
      </Button>
    </div>
  );
}

const columns = (
  onDeleteItem: (row: NewsForm) => void,
  onSelectItem: (row: NewsForm) => void
): ColumnDescription[] => [
    {
      dataField: 'id',
      text: 'No #',
      headerStyle: { width: 60 }
    }, {
      dataField: 'title',
      text: 'Tiêu đề',
      filter: textFilter({
        defaultValue: '',
        delay: 0,
        placeholder: 'Nhập tiêu đề',
      }),
      headerStyle: { width: 200 }
    },
    {
      dataField: "categoryName",
      text: "Chuyên mục",
      filter: selectFilter({
        placeholder: "Tất cả",
        options: optionsCategory,
      }),
      headerStyle: { width: 100 },
    },
    {
      dataField: 'createdAt',
      text: 'Ngày tạo',
      formatter: (row) => {
        return formatDateToDisplay(row, 'dd/MM/yyyy HH:mm:ss', "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as string;
      },
      headerStyle: { width: 120 }
    },
    {
      dataField: 'createdBy',
      text: 'Người tạo',
      headerStyle: { width: 100 }
    },
    {
      dataField: 'action',
      text: '',
      formatter: actionFormatter(onDeleteItem, onSelectItem),
      headerStyle: { width: 170 }
    }
  ];


const News = () => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<NewsState>({
    page: 1,
    data: [],
    totalSize: 0,
    sizePerPage: config.sizePerPage,
    filters: {},
    loading: false,
    submitting: false,
    clearError: false,
    showDeleteModal: false,
    showViewModal: false,
  });

  const ref = React.useRef<{
    sizePerPage?: number;
    page?: number;
    filters?: Obj;
    type?: TableChangeType;
    newState?: TableChangeState<Obj>;
    categoryFilter?: string;
  }>({});

  const { newsList, newsResult } = useSelector(
    (state: State) => ({
      newsList: state.newsList,
      newsResult: state.newsActionResult,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(queryNewsList({
      limit: state.sizePerPage,
      page: state.page,
      withCount: true
    }));
  }, []);

  React.useEffect(() => {
    if (newsList && newsList.success && newsList.response) {
      const { response } = newsList;
      setState((prevState) => ({
        ...prevState,
        data: response.data as Obj[],
        totalSize: response.totalCount as number,
        page: response.page as number,
        loading: false
      }));
    }
  }, [newsList]);

  React.useEffect(() => {
    if (newsResult) {
      if (!newsResult.success) {
        setState((prevState) => ({
          ...prevState,
          clearError: false,
          submitting: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showDeleteModal: false,
          clearError: true,
          submitting: false,
        }));

        dispatch(queryNewsList({
          limit: state.sizePerPage,
          page: state.page,
          withCount: true,
          ...state.filters
        }));
      }
    }
  }, [newsResult]);


  const handleTableChange = (type: TableChangeType, newState: TableChangeState<Obj>, query?: boolean) => {
    let categoryName = ref.current?.categoryFilter;
    if (!query) {
      ref.current.type = type;
      ref.current.sizePerPage = newState.sizePerPage;
      ref.current.page = newState.page;
      ref.current.filters = newState.filters;
      ref.current.type = type;
      ref.current.newState = newState;
      if (
        newState.filters["categoryName"] !== undefined ||
        (newState.filters["categoryName"] === undefined &&
          ref.current?.categoryFilter)
        || type !== 'filter'
      ) {
        categoryName = newState.filters["categoryName"]?.filterVal as string;
        if (ref.current?.categoryFilter !== categoryName || type !== 'filter') {
          setState((prevState) => ({
            ...prevState,
            sizePerPage: newState.sizePerPage,
            page: newState.page,
            filters: newState.filters,
            loading: true,
          }));

          const filters: Obj = {};
          Object.keys(newState.filters).forEach((key) => {
            filters[key] = newState.filters[key].filterVal;
          });

          dispatch(
            queryNewsList({
              limit: newState.sizePerPage,
              page: newState.page,
              withCount: true,
              ...filters,
            })
          );
          ref.current.categoryFilter = categoryName;
        }
      }
    } else {
      if (query) {
        if (ref && ref.current?.newState) {
          setState((prevState) => ({
            ...prevState,
            loading: true,
          }));
          const filters: Obj = {};
          Object.keys(ref.current?.newState?.filters).forEach((key) => {
            filters[key] = ref.current?.newState?.filters[key].filterVal;
          });

          dispatch(
            queryNewsList({
              limit: ref.current?.newState?.sizePerPage,
              page: ref.current?.newState?.page,
              withCount: true,
              ...filters,
            })
          );
        }
      }
    }
  }

  const onDeleteItem = (row: NewsForm) => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: true,
      formData: row
    }));
  }

  const onSelectItem = (row: NewsForm) => {
    setState((prevState) => ({
      ...prevState,
      showViewModal: true,
      formData: row,
    }));
  };

  const handleCloseViewModal = () => {
    setState((prevState) => ({
      ...prevState,
      showViewModal: false,
    }));
  };

  const handleCloseDeleteModal = () => {
    setState((prevState) => ({
      ...prevState,
      clearError: true,
      showDeleteModal: false
    }));
  }

  const handleSubmit = (values: NewsForm, actions: FormikHelpers<NewsForm>) => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));

    dispatch(actionNews(NEWS_DELETE, values))
  }

  return (
    <Container>
      <div className="page-header d-flex justify-content-between align-items-center">
        <div className="page-title">Tin tức</div>
        <Link to="/news/create">
          <Button variant="success">Tạo tin mới</Button>
        </Link>
      </div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={state.formData!}
        enableReinitialize={true}
        validateOnMount
      >
        {({ handleSubmit }) =>
          state.showDeleteModal && (
            <Form id="newsForm" onSubmit={handleSubmit} className="form m-auto">
              <Modal
                size="sm"
                show={state.showDeleteModal}
                onHide={handleCloseDeleteModal}
                centered={true}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Xóa tin</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                  Bạn có chắc chắn muốn xóa tin này không?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseDeleteModal}>
                    Đóng
                  </Button>
                  <Button
                    type="submit"
                    form="newsForm"
                    variant="primary"
                    disabled={state.submitting}
                  >
                    Xóa tin
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )
        }
      </Formik>
      <Modal
        size="sm"
        show={state.showViewModal}
        onHide={handleCloseViewModal}
        centered={true}
        className="Modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{state.formData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <iframe
            src={state.formData?.url}
            allowFullScreen
            allow="autoplay; encrypted-media"
            width="100%"
            height="310"
            frameBorder="0"
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewModal}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>

      <DataTable
        data={state.data}
        columns={columns(onDeleteItem, onSelectItem)}
        page={state.page}
        totalSize={state.totalSize}
        sizePerPage={state.sizePerPage}
        onTableChange={handleTableChange}
        loading={state.loading}
      />
    </Container>
  );
}

export default News;