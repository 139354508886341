import { createReducer } from "utils/redux";

export const USER_QUERY_LIST_SUCCESS = 'USER_QUERY_LIST_SUCCESS';
export const USER_QUERY_LIST_FAILED = 'USER_QUERY_LIST_FAILED';

export const USER_ACTION_SUCCESS = 'USER_ACTION_SUCCESS';
export const USER_ACTION_FAILED = 'USER_ACTION_FAILED';

export const UserList = createReducer(
    USER_QUERY_LIST_SUCCESS,
    USER_QUERY_LIST_FAILED
);

export const UserActionResult = createReducer(
    USER_ACTION_SUCCESS,
    USER_ACTION_FAILED
);