export enum PERMISSION {
  ADMIN = 1,
  EXPERT,
  SUPER_ADMIN,
}

export enum CATEGORY {
  KHUYEN_NGU = 1,
  CHAN_NUOI,
  TRONG_TROT,
  KHAC
}

export enum MARKET_PRICE_TYPE {
  TOM = 'Tôm',
  CA = 'Cá',
  CA_PHE = 'Cà phê',
  LUA = 'Lúa',
  HO_TIEU = 'Hồ tiêu',
  CAO_SU = 'Cao su',
  TRAI_CAY = 'Trái cây',
  DIEU = 'Điều'
}