import { Obj } from 'interface/common';
import { FARMER_QUERY_LIST, FARMER_EXPORT_LIST } from 'redux-saga/actions';
import { 
  FARMER_QUERY_LIST_SUCCESS, 
  FARMER_QUERY_LIST_FAILED, 
  FARMER_EXPORT_LIST_FAILED,
  FARMER_EXPORT_LIST_SUCCESS
} from './reducers';

export const queryFarmerList = (payload: Obj) => ({
  type: FARMER_QUERY_LIST,
  response: {
    success: {
      type: FARMER_QUERY_LIST_SUCCESS,
    },
    failure: {
      type: FARMER_QUERY_LIST_FAILED,
    },
  },
  payload
});

export const exportFarmerList = (payload: Obj) => ({
  type: FARMER_EXPORT_LIST,
  response: {
    success: {
      type: FARMER_EXPORT_LIST_SUCCESS,
    },
    failure: {
      type: FARMER_EXPORT_LIST_FAILED,
    },
  },
  payload
});

