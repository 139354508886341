import { FILE_UPLOAD_IMAGE } from 'redux-saga/actions';
import { METHOD } from 'utils/api';
import { watchQuery } from 'utils/sagas';

export function* uploadImage() {
  return yield watchQuery(
    FILE_UPLOAD_IMAGE,
    '/file/images',
    METHOD.POST
  );
}
