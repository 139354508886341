import { QUESTION_DELETE, QUESTION_QUERY_LIST } from 'redux-saga/actions';
import { METHOD } from 'utils/api';
import { watchQuery } from 'utils/sagas';

export function* queryQuestionList() {
  return yield watchQuery(
    QUESTION_QUERY_LIST,
    '/question',
    METHOD.GET
  );
}


export function* deleteQuestion() {
  return yield watchQuery(
    QUESTION_DELETE,
    '/question/{id}',
    METHOD.DELETE
  );
}
