import { Obj } from 'interface/common';
import { MARKET_PRICE_LOAD_ITEM } from 'redux-saga/actions';
import { MarketPriceForm } from '.';
import {
    MARKET_PRICE_LOAD_ITEM_FAILED,
    MARKET_PRICE_LOAD_ITEM_SUCCESS,
    MARKET_PRICE_ACTION_SUCCESS,
    MARKET_PRICE_ACTION_FAILED,
} from './reducers';


export const loadMarketPrice = (payload: Obj) => ({
    type: MARKET_PRICE_LOAD_ITEM,
    response: {
        success: {
            type: MARKET_PRICE_LOAD_ITEM_SUCCESS,
        },
        failure: {
            type: MARKET_PRICE_LOAD_ITEM_FAILED,
        },
    },
    payload
});

export const actionMarketPrice = (type: string, payload: MarketPriceForm) => ({
    type,
    response: {
        success: {
            type: MARKET_PRICE_ACTION_SUCCESS,
        },
        failure: {
            type: MARKET_PRICE_ACTION_FAILED,
        },
    },
    payload
});