import { createReducer } from "utils/redux";

export const VIDEO_QUERY_LIST_SUCCESS = 'VIDEO_QUERY_LIST_SUCCESS';
export const VIDEO_QUERY_LIST_FAILED = 'VIDEO_QUERY_LIST_FAILED';

export const VIDEO_ACTION_SUCCESS = 'VIDEO_ACTION_SUCCESS';
export const VIDEO_ACTION_FAILED = 'VIDEO_ACTION_FAILED';
export const VIDEO_ACTION_CLEAR = 'VIDEO_ACTION_CLEAR';

export const VideoList = createReducer(
  VIDEO_QUERY_LIST_SUCCESS,
  VIDEO_QUERY_LIST_FAILED
);

export const VideoActionResult = createReducer(
  VIDEO_ACTION_SUCCESS,
  VIDEO_ACTION_FAILED,
  VIDEO_ACTION_CLEAR
);
