import { FILE_UPLOAD_IMAGE } from 'redux-saga/actions';
import { FILE_UPLOAD_FAILED, FILE_UPLOAD_SUCCESS } from './reducers';

export const uploadImage = (payload: FormData) => ({
  type: FILE_UPLOAD_IMAGE,
  response: {
    success: {
      type: FILE_UPLOAD_SUCCESS,
    },
    failure: {
      type: FILE_UPLOAD_FAILED,
    },
  },
  payload
});