import { VIDEO_CREATE, VIDEO_DELETE, VIDEO_QUERY_LIST, VIDEO_UPDATE } from 'redux-saga/actions';
import { METHOD } from 'utils/api';
import { watchQuery } from 'utils/sagas';

export function* queryVideoList() {
  return yield watchQuery(
    VIDEO_QUERY_LIST,
    '/video',
    METHOD.GET
  );
}

export function* createVideo() {
  return yield watchQuery(
    VIDEO_CREATE,
    '/video',
    METHOD.POST
  );
}

export function* updateVideo() {
  return yield watchQuery(
    VIDEO_UPDATE,
    '/video/{id}',
    METHOD.PUT
  );
}

export function* deleteVideo() {
  return yield watchQuery(
    VIDEO_DELETE,
    '/video/{id}',
    METHOD.DELETE
  );
}