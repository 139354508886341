import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { Button, Container, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Obj } from 'interface/common';
import Picker from 'component/Picker';
import ContentEditor from 'component/ContentEditor';
import DatePicker from 'component/DatePicker';
import { State } from 'redux-saga/reducers';
import { MARKET_PRICE_TYPE } from 'constant';
import { formatDateToString, formatStringToDate } from 'utils/datetime';
import { loadMarketPrice, actionMarketPrice } from './actions';
import { MARKET_PRICE_CREATE, MARKET_PRICE_UPDATE } from 'redux-saga/actions';
import { MARKET_PRICE_ACTION_CLEAR, MARKET_PRICE_LOAD_ITEM_CLEAR } from './reducers'
import { optionsMarketPrice } from 'global';


const schema = yup.object().shape({
    title: yup.string().required('Tiêu đề không được để trống'),
    type: yup.string().typeError('Phải chọn loại nông sản').required('Phải chọn loại nông sản'),
    date: yup.string().required('Ngày không được để trống'),
    content: yup.string().required('Nội dung không được để trống')
});

export interface MarketPriceForm {
    id?: string;
    title: string;
    date: string;
    formattedDate?: string;
    type: MARKET_PRICE_TYPE | null;
    content: string;
    contentUrl?: string;
}

const MarketPriceFormComp = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams<{ id?: string }>();

    let initialValues: MarketPriceForm = {
        title: '',
        type: null,
        date: formatDateToString(new Date(), 'dd/MM/yyyy') as string,
        formattedDate: formatDateToString(new Date(), 'dd/MM/yyyy') as string,
        content: ''
    };

    const [state, setState] = React.useState({
        submitting: false,
        clearError: false,
        initialValues
    });

    const { marketPriceResult, marketPriceItem } = useSelector(
        (state: State) => ({
            marketPriceResult: state.marketPriceActionResult,
            marketPriceItem: state.marketPriceItem
        }),
        shallowEqual
    );

    React.useEffect(() => {
        if (marketPriceItem && marketPriceItem.success) {
            if (marketPriceItem.success && (marketPriceItem.response as Obj).id === Number(id)) {
                setState((prevState) => ({
                    ...prevState,
                    initialValues: marketPriceItem.response as unknown as MarketPriceForm,
                }));
            }
        }
    }, [marketPriceItem]);

    React.useEffect(() => {
        if (id) {
            dispatch(loadMarketPrice({
                id: Number(id),
            }));
        }

        return () => {
            dispatch({
                type: MARKET_PRICE_ACTION_CLEAR
            });
            dispatch({
                type: MARKET_PRICE_LOAD_ITEM_CLEAR
            });
        }
    }, []);

    React.useEffect(() => {
        if (marketPriceResult) {
            if (!marketPriceResult.success) {
                setState((prevState) => ({
                    ...prevState,
                    clearError: false,
                    submitting: false,
                }));
            } else {
                history.push('/market-price');
            }
        }

    }, [marketPriceResult]);

    const handleSubmit = (values: MarketPriceForm, actions: FormikHelpers<MarketPriceForm>) => {
        setState((prevState) => ({
            ...prevState,
            submitting: true,
        }));
        dispatch(actionMarketPrice(id ? MARKET_PRICE_UPDATE : MARKET_PRICE_CREATE, { ...values, ...{ id } }));
    }

    const onFormChange = () => {        
        setState((prevState) => ({
            ...prevState,
            clearError: true
        }));
    }

    const goBack = () => {
        history.push('/market-price');
    }

    return (
        (id == null || marketPriceItem != null) ?
            <Container>
                <Formik
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                    initialValues={state.initialValues}
                    enableReinitialize={true}
                    validateOnMount
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        touched,
                        errors,
                        isValid
                    }) => (
                        <Form onChange={onFormChange} onSubmit={handleSubmit} className="form m-auto">
                            <div className="form-header">
                                {id != null ? `Cập nhật giá thị trường #${id}` : 'Tạo giá thị trường mới'}
                            </div>
                            <div className="form-body">
                                <Form.Group controlId="title">
                                    <Form.Label className="d-block">Tiêu đề</Form.Label>
                                    <Form.Control
                                        name="title"
                                        type="text"
                                        placeholder="Nhập tiêu đề"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={touched.title && !errors.title}
                                        value={values.title}
                                        className={touched.title && errors.title ? "error" : undefined}
                                    />
                                    {touched.title && errors.title && (
                                        <p className="error">{errors.title}</p>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="type">
                                    <Form.Label className="d-block">Chuyên mục</Form.Label>
                                    <Picker
                                        value={values.type!}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        options={optionsMarketPrice}
                                        placeholder="Chọn loại"
                                        name="type"
                                    />
                                    {touched.type && errors.type && (
                                        <p className="error">{errors.type}</p>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="formattedDate">
                                    <Form.Label className="d-block">Ngày</Form.Label>
                                    <DatePicker name="formattedDate" onChange={setFieldValue} onBlur={setFieldTouched} selected={formatStringToDate(values.formattedDate, 'dd/MM/yyyy')} />
                                </Form.Group>
                                <Form.Group controlId="content">
                                    <Form.Label className="d-block">Nội dung</Form.Label>
                                    <ContentEditor initialValue={state.initialValues.content} onChange={setFieldValue} onBlur={setFieldTouched} />
                                    {touched.content && errors.content && (
                                        <p className="error">{errors.content}</p>
                                    )}
                                </Form.Group>
                                <Form.Group className="text-center d-flex flex-row">
                                    <Button variant="outline-secondary" className="flex-fill mr-1 py-2" onClick={goBack}>Hủy</Button>
                                    <Button type="submit" variant="primary" className="flex-fill ml-1 py-2" disabled={!isValid || state.submitting}>Lưu giá thị trường</Button>
                                </Form.Group>
                                <Form.Group>
                                    {!state.clearError && marketPriceResult && !marketPriceResult.success && marketPriceResult.error && (
                                        <p className="error">{marketPriceResult.error.message}</p>
                                    )}
                                </Form.Group>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container> : null
    )
}

export default MarketPriceFormComp;