import { METHOD } from "utils/api";
import { watchQuery } from "utils/sagas";
import { USER_QUERY_LIST, PROFILE_CHANGE_PASSWORD, USER_UPDATE, EXPERT_CREATE } from "redux-saga/actions";

export function* queryUserList() {
    return yield watchQuery(
        USER_QUERY_LIST,
        '/user',
        METHOD.GET
    );
}

export function* updateUser() {
    return yield watchQuery(
        USER_UPDATE,
        '/user/{id}',
        METHOD.PUT
    );
}

export function* changePassword() {
    return yield watchQuery(
        PROFILE_CHANGE_PASSWORD,
        '/user/change_password_me',
        METHOD.PUT
    );
}

export function* createExpert() {
    return yield watchQuery(
        EXPERT_CREATE,
        '/user/add_expert/',
        METHOD.POST
    );
}