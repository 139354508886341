
import { ANSWER_ACTION_FAILED, ANSWER_ACTION_SUCCESS } from "./reducers";

export const actionAnswer = (type: string, payload: FormData) => ({
    type,
    response: {
        success: {
            type: ANSWER_ACTION_SUCCESS,
        },
        failure: {
            type: ANSWER_ACTION_FAILED,
        },
    },
    payload
});
