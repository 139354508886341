import * as React from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Container, Modal } from 'react-bootstrap';
import { TableChangeType, TableChangeState, ColumnDescription } from 'react-bootstrap-table-next';
import { customFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { FaEdit, FaEye, FaTrashAlt, FaCircle } from 'react-icons/fa';
import { Obj } from 'interface/common';
import { formatDateToDisplay, formatStringToDate } from 'utils/datetime';
import config from 'config';
import { optionsMarketPrice } from 'global';
import DataTable, { DataTableState } from 'component/DataTable';
import DatePicker from 'component/DatePicker';
import { queryMarketPriceList } from './actions';
import { actionMarketPrice } from 'screen/MarketPriceForm/actions';
import { State } from 'redux-saga/reducers';
import { MarketPriceForm } from 'screen/MarketPriceForm';
import { MARKET_PRICE_DELETE } from 'redux-saga/actions';
import './styles.scss';

interface MarketPricesState extends DataTableState {
  loading: boolean;
  submitting: boolean;
  showDeleteModal: boolean;
  showViewModal: boolean;
  formData?: MarketPriceForm;
}

const MarketPrice = () => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<MarketPricesState>({
    page: 1,
    data: [],
    totalSize: 0,
    sizePerPage: config.sizePerPage,
    filters: {},
    loading: false,
    submitting: false,
    showDeleteModal: false,
    showViewModal: false,
  });

  const ref = React.useRef<{
    sizePerPage?: number;
    page?: number;
    filters?: Obj;
    type?: TableChangeType;
    newState?: TableChangeState<Obj>;
    categoryFilter?: string;
    dateFilter?: string;
  }>({});

  const [date, setDate] = React.useState(undefined)

  const { marketPriceList, marketPriceResult } = useSelector(
    (state: State) => ({ marketPriceList: state.marketPriceList, marketPriceResult: state.marketPriceActionResult }),
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(queryMarketPriceList({
      limit: state.sizePerPage,
      page: state.page,
      withCount: true,
    }));
  }, []);

  React.useEffect(() => {
    if (marketPriceList && marketPriceList.success && marketPriceList.response) {
      const { response } = marketPriceList;
      setState((prevState) => ({
        ...prevState,
        data: response.data as Obj[],
        totalSize: response.totalCount as number,
        page: response.page as number,
        loading: false
      }));
    }
  }, [marketPriceList]);

  React.useEffect(() => {
    if (marketPriceResult) {
      if (!marketPriceResult.success) {
        setState((prevState) => ({
          ...prevState,
          clearError: false,
          submitting: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showDeleteModal: false,
          clearError: true,
          submitting: false,
        }));

        dispatch(queryMarketPriceList({
          limit: state.sizePerPage,
          page: state.page,
          withCount: true,
          ...state.filters
        }));
      }
    }
  }, [marketPriceResult]);

  const actionFormatter = (
    onDeleteItem: (row: MarketPriceForm) => void,
    onSelectItem: (row: MarketPriceForm) => void
  ) => (cell: Obj, row: MarketPriceForm) => {
    return (
      <div className="d-flex justify-content-center">
        <Link to={`/market-price/${row.id}`}>
          <Button
            variant="outline-primary"
            className="mr-2"
            title="Chỉnh sửa giá thị trường"
          >
            <FaEdit size={20} />
          </Button>
        </Link>
        <Button
          variant="outline-danger"
          className="mr-2"
          title="Xóa giá thị trường"
          onClick={() => onDeleteItem(row)}
        >
          <FaTrashAlt size={20} />
        </Button>
        <Button
          variant="outline-success"
          className="mr-2"
          title="Xem giá thị trường"
          onClick={() => onSelectItem(row)}
        >
          <FaEye size={20} />
        </Button>
      </div>
    );
  };

  const columns = (
    onDeleteItem: (row: MarketPriceForm) => void,
    onSelectItem: (row: MarketPriceForm) => void
  ): ColumnDescription[] => [
      {
        dataField: "id",
        text: "No #",
        headerStyle: { width: 60 },
      },
      {
        dataField: "title",
        text: "Tiêu đề",
        filter: textFilter({
          defaultValue: "",
          placeholder: "Nhập tiêu đề",
          delay: 0,
        }),
        headerStyle: { width: 200 },
      },
      {
        dataField: "type",
        text: "Loại",
        headerStyle: { width: 100 },
        filter: selectFilter({
          placeholder: "Tất cả",
          options: (optionsMarketPrice as unknown) as {
            [index: string]: string;
          },
        }),
      },
      {
        dataField: "date",
        text: "Ngày",
        filter: customFilter({}),
        ...{
          filterRenderer: (onFilter: Function) => {
            const change = (value: string) => {
              setDate(
                value
                  ? (formatStringToDate(value, "dd/MM/yyyy") as any)
                  : undefined
              );
              onFilter(value);
            };
            return (
              <div className="filter-label">
                <DatePicker
                  name="date"
                  onChangeFilter={change}
                  selected={date}
                  isFilter={true}
                />
              </div>
            );
          },
        },
        headerStyle: { width: 200 },
        formatter: (row) => {
          return formatDateToDisplay(
            row,
            "dd/MM/yyyy",
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
          ) as string;
        },
      },
      {
        dataField: "createdAt",
        text: "Ngày tạo",
        headerStyle: { width: 120 },
        formatter: (row) => {
          return formatDateToDisplay(
            row,
            "dd/MM/yyyy HH:mm:ss",
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
          ) as string;
        },
      },
      {
        dataField: "createdBy",
        text: "Người tạo",
        headerStyle: { width: 100 },
      },
      {
        dataField: "action",
        text: "",
        formatter: actionFormatter(onDeleteItem, onSelectItem),
        headerStyle: { width: 170 },
      },
    ];

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<Obj>,
    query?: boolean
  ) => {
    let categoryName = ref.current?.categoryFilter;
    let date = ref.current?.dateFilter;
    if (!query) {
      ref.current.type = type;
      ref.current.sizePerPage = newState.sizePerPage;
      ref.current.page = newState.page;
      ref.current.filters = newState.filters;
      ref.current.type = type;
      ref.current.newState = newState;

      if (
        newState.filters["type"] !== undefined ||
        (newState.filters["type"] === undefined &&
          ref.current?.categoryFilter) ||
        newState.filters["date"] !== undefined ||
        (newState.filters["date"] === undefined && ref.current?.dateFilter)
        || type !== 'filter'
      ) {
        categoryName = newState.filters["type"]?.filterVal as string;
        date = newState.filters["date"]?.filterVal as string;
        if (
          (ref.current?.categoryFilter !== categoryName ||
            ref.current?.dateFilter !== date) || type !== 'filter'
        ) {
          setState((prevState) => ({
            ...prevState,
            sizePerPage: newState.sizePerPage,
            page: newState.page,
            filters: newState.filters,
            loading: true,
          }));

          const filters: Obj = {};
          Object.keys(newState.filters).forEach((key) => {
            filters[key] = newState.filters[key].filterVal;
          });

          dispatch(
            queryMarketPriceList({
              limit: newState.sizePerPage,
              page: newState.page,
              withCount: true,
              ...filters,
            })
          );
          ref.current.categoryFilter = categoryName;
          ref.current.dateFilter = date;
        }
      }
    } else {
      if (query) {
        if (ref && ref.current?.newState) {
          setState((prevState) => ({
            ...prevState,
            loading: true,
          }));
          const filters: Obj = {};
          Object.keys(ref.current?.newState?.filters).forEach((key) => {
            filters[key] = ref.current?.newState?.filters[key].filterVal;
          });

          dispatch(
            queryMarketPriceList({
              limit: ref.current?.newState?.sizePerPage,
              page: ref.current?.newState?.page,
              withCount: true,
              ...filters,
            })
          );
        }
      }
    }
  };

  const handleCloseDeleteModal = () => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: false
    }));
  }

  const handleCloseViewModal = () => {
    setState((prevState) => ({
      ...prevState,
      showViewModal: false,
    }));
  };

  const onDeleteItem = (row: MarketPriceForm) => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: true,
      formData: row
    }));
  }

  const onSelectItem = (row: MarketPriceForm) => {
    setState((prevState) => ({
      ...prevState,
      showViewModal: true,
      formData: row,
    }));
  };

  const onDeleteVideo = () => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));
    dispatch(actionMarketPrice(MARKET_PRICE_DELETE, state.formData as MarketPriceForm));
  }

  return (
    <Container>
      <div className="page-header d-flex justify-content-between align-items-center">
        <div className="page-title">Giá thị trường</div>
        <Link to="/market-price/create">
          <Button variant="success">Tạo giá thị trường mới</Button>
        </Link>
      </div>
      <Modal
        size="sm"
        show={state.showDeleteModal}
        onHide={handleCloseDeleteModal}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{state.formData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          Bạn có chắc chắn muốn xóa giá thị trường này không?
          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Đóng
            </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={state.submitting}
            onClick={onDeleteVideo}
          >
            Xóa giá thị trường
            </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        show={state.showViewModal}
        onHide={handleCloseViewModal}
        centered={true}
        className="Modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{state.formData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <iframe
            src={state.formData?.contentUrl}
            allowFullScreen
            allow="autoplay; encrypted-media"
            width="100%"
            height="310"
            frameBorder="0"
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewModal}>
            Đóng
            </Button>
        </Modal.Footer>
      </Modal>
      <DataTable
        data={state.data}
        columns={columns(onDeleteItem, onSelectItem)}
        page={state.page}
        totalSize={state.totalSize}
        sizePerPage={state.sizePerPage}
        onTableChange={handleTableChange}
        loading={state.loading}
      />
    </Container>
  );
}

export default MarketPrice;