import { Global } from 'global';
import { Action, User } from 'interface/common';
import { LOGIN_SUCCESS } from 'screen/Login/reducers';
import { removeKey, setKey } from 'utils/localStorage';

export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_FAILED = 'VERIFY_TOKEN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const AuthState = (state: boolean | null = null, action: Action<User | {
  response: User;
} | {
  response: {
    token: string;
    user: User;
  }
}>) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      delete Global.me;
      removeKey('token');
      return false;
    case VERIFY_TOKEN_SUCCESS:
      Global.me = (action.payload as {
        response: User;
      }).response as User;
      return true;
    case VERIFY_TOKEN_FAILED:
      return false;
    case LOGIN_SUCCESS:
      Global.jwtToken = (action.payload as {
        response: {
          token: string;
          user: User;
        }
      }).response.token;
      setKey('token', Global.jwtToken);
      Global.me = (action.payload as {
        response: {
          token: string;
          user: User;
        }
      }).response.user as User;
      return true;
    default:
      return state;
  }
};
