
import { useState } from 'react';
import { FaAngleRight, FaBars, FaRegUser, FaSignOutAlt, FaUserCircle, FaUsersCog } from 'react-icons/fa';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Global } from 'global';
import { isSuperAdmin } from 'utils/common';
import { PERMISSION } from 'constant';
import { State } from 'redux-saga/reducers';
import { LOGOUT_SUCCESS } from 'router/reducers';
import './styles.scss';

const Menu = ({ title }: { title?: string }) => {
  const dispatch = useDispatch();
  const defaultTitle = 'Quản trị Khuyến Nông';
  const authState = useSelector(
    (state: State) => state.authState,
    shallowEqual
  );
  const [profileToggle, setProfileToggle] = useState<boolean>(false);
  const [mobileMenuToggle, setMobileMenuToggle] = useState<boolean>(false);

  const showMobileMenu = () => {
    setMobileMenuToggle(true);
  }

  const hideMobileMenu = () => {
    setMobileMenuToggle(false);
  }

  const toggleProfile = () => {
    setProfileToggle(!profileToggle);
  }

  const handleLogout = () => {
    dispatch({
      type: LOGOUT_SUCCESS
    });
  }

  const UserProfle = (
    <NavLink className="py-3 px-3 d-flex flex-row align-items-center justify-content-between" to="/profile">
      <div className="d-flex justify-content-between align-items-center">
        <FaUserCircle className="mr-2 mt-2" fontSize={40} />
        <p className="mb-0">{Global.me?.username}</p>
      </div>
      <FaAngleRight />
    </NavLink>
  );

  return (
    <div className="menu">
      {authState ? (
        <>
          {mobileMenuToggle && <>
            <div className="menu-sidebar h-100">
              {UserProfle}
              <ul className="mt-3 p-0">
                <li>
                  <NavLink to="/news">Tin tức</NavLink>
                </li>
                <li>
                  <NavLink to="/video">Video</NavLink>
                </li>
                <li>
                  <NavLink to="/document">Tài liệu</NavLink>
                </li>
                <li>
                  <NavLink to="/market-price">Giá cả thị trường</NavLink>
                </li>
                <li>
                  <NavLink to="/user">Người dùng</NavLink>
                </li>
              </ul>
              <div
                className="log-out py-3 px-3 cursor-pointer text-left"
                onClick={handleLogout}
              >
                <FaSignOutAlt /> Đăng xuất
              </div>
            </div>
            <div
              onClick={hideMobileMenu}
              className="menu-sidebar-opacity"
            ></div>
          </>
          }
          <div className="menu-mobile">
            <div
              className="menu-icon d-flex align-items-center"
            >
              <FaBars onClick={showMobileMenu} className="ml-2 cursor-pointer" fontSize={28} />
            </div>
            <div className="logo flex-fill justify-content-center">{title ? title : defaultTitle}</div>
            <NavLink className="p-3 h-100 d-flex align-items-center cursor-pointer menu-icon" id="admin" to="/admin">
              <FaUsersCog fontSize={24} />
            </NavLink>
          </div>
        </>
      ) :
        <div className="menu-mobile__no-login d-block d-md-none">
          <div className="logo">{title ? title : defaultTitle}</div>
        </div>
      }
      <div className="menu-web">
        <div className="logo d-none d-md-block">{title ? title : defaultTitle}</div>
        {authState && (
          <>
            <div className="menu-router">
              <NavLink id="news" to="/news">
                Tin tức
              </NavLink>
              <NavLink id="video" to="/video">
                Video
              </NavLink>
              <NavLink id="document" to="/document">
                Tài liệu
              </NavLink>
              <NavLink id="market" to="/market-price">
                Giá cả thị trường
              </NavLink>
              <NavLink id="user" to="/question">
                Câu hỏi
              </NavLink>
              {isSuperAdmin(Global.me?.permissions as PERMISSION[]) && 
              <NavLink id="user" to="/user">
                Người dùng
              </NavLink>
              }
              {isSuperAdmin(Global.me?.permissions as PERMISSION[]) && 
              <NavLink id="user" to="/farmer">
                Nông hộ
              </NavLink>
              }
            </div>
            <div className="header-action h-100 d-flex align-items-center mr-2">
              {isSuperAdmin(Global.me?.permissions as PERMISSION[]) && <NavLink className="p-3 h-100 d-flex align-items-center cursor-pointer user-icon" id="admin" to="/admin">
                <FaUsersCog fontSize={24} />
              </NavLink>}
              <div
                className={`p-3 h-100 d-flex align-items-center cursor-pointer user-icon ${profileToggle ? 'active' : ''
                  }`}
                onClick={toggleProfile}
              >
                <FaRegUser fontSize={24} />
              </div>
            </div>
            {
              profileToggle && (
                <div className="profile-menu">
                  <div className="box-item p-0">
                    {UserProfle}
                    <div
                      className="log-out py-3 px-3 cursor-pointer text-left"
                      onClick={handleLogout}
                    >
                      <FaSignOutAlt /> Đăng xuất
                    </div>
                  </div>
                </div>
              )
            }
          </>
        )}
      </div>
    </div>
  );
};

export default Menu;
