import { CATEGORY, MARKET_PRICE_TYPE, PERMISSION } from 'constant';
import { User } from 'interface/common';

interface GlobalInterface {
  jwtToken?: string;
  me?: User;
}

export const Global: GlobalInterface = {};

export class CustomError extends Error {
  type: string;
  code: number;
  errors?: { field: string, messages: Array<string> }[];

  constructor(type: string, code: number, message: string, errors?: { field: string, messages: Array<string> }[]) {
    super(message);

    this.type = type;
    this.code = code;
    this.errors = errors;
  }
}

export const optionsCategory = [
  { value: CATEGORY.KHUYEN_NGU, label: 'Khuyến ngư' },
  { value: CATEGORY.CHAN_NUOI, label: 'Chăn nuôi' },
  { value: CATEGORY.TRONG_TROT, label: 'Trồng trọt' },
  { value: CATEGORY.KHAC, label: 'Khác' }
]

export const optionsMarketPrice = [
  { value: MARKET_PRICE_TYPE.TOM, label: 'Tôm' },
  { value: MARKET_PRICE_TYPE.CA, label: 'Cá' },
  { value: MARKET_PRICE_TYPE.CA_PHE, label: 'Cà phê' },
  { value: MARKET_PRICE_TYPE.LUA, label: 'Lúa' },
  { value: MARKET_PRICE_TYPE.HO_TIEU, label: 'Hồ tiêu' },
  { value: MARKET_PRICE_TYPE.CAO_SU, label: 'Cao su' },
  { value: MARKET_PRICE_TYPE.TRAI_CAY, label: 'Trái cây' },
  { value: MARKET_PRICE_TYPE.DIEU, label: 'Điều' }
]

export enum EmbedType {
  horizontalRule = "horizontal",
  image = "image"
}