import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { Button, Container, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Obj } from 'interface/common';
import Picker from 'component/Picker';
import ContentEditor from 'component/ContentEditor';
import { State } from 'redux-saga/reducers';
import { NEWS_ACTION_CLEAR, NEWS_LOAD_ITEM_CLEAR } from './reducers';
import { NEWS_UPDATE, NEWS_CREATE } from 'redux-saga/actions';
import { actionNews, loadNews } from './actions';
import { optionsCategory } from 'global';

const schema = yup.object().shape({
  title: yup.string().required('Tiêu đề không được để trống'),
  categoryId: yup.number().typeError('Phải chọn chuyên mục').required('Phải chọn chuyên mục'),
  content: yup.string().required('Nội dung không được để trống')
});

export interface NewsForm {
  id?: string;
  title: string;
  categoryId: number | null;
  shortDescription?: string;
  content: string;
  url?: string;
}

const NewsFormComp = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<{ id?: string }>();

  let initialValues: NewsForm = {
    title: '',
    categoryId: null,
    content: ''
  };

  const [state, setState] = React.useState({
    submitting: false,
    clearError: false,
    initialValues
  });

  const { newsResult, newsItem } = useSelector(
    (state: State) => ({
      newsResult: state.newsActionResult,
      newsItem: state.newsItem
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (newsItem && newsItem.success) {
      if (newsItem.success && (newsItem.response as Obj).id === Number(id)) {
        setState((prevState) => ({
          ...prevState,
          initialValues: newsItem.response as unknown as NewsForm,
        }));
      }
    }
  }, [newsItem]);

  React.useEffect(() => {
    if (id) {
      dispatch(loadNews({
        id: Number(id),
        fromAdmin: true
      }));
    }

    return () => {
      dispatch({
        type: NEWS_ACTION_CLEAR
      });
      dispatch({
        type: NEWS_LOAD_ITEM_CLEAR
      });
    }
  }, []);

  React.useEffect(() => {
    if (newsResult) {
      if (!newsResult.success) {
        setState((prevState) => ({
          ...prevState,
          clearError: false,
          submitting: false,
        }));
      } else {
        history.push('/news');
      }
    }

  }, [newsResult]);

  const handleSubmit = (values: NewsForm, actions: FormikHelpers<NewsForm>) => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));
    dispatch(actionNews(id ? NEWS_UPDATE : NEWS_CREATE, { ...values, ...{ id } }));
  }

  const onFormChange = () => {
    setState((prevState) => ({
      ...prevState,
      clearError: true
    }));
  }

  const goBack = () => {
    history.push('/news');
  }

  return (
    (id == null || newsItem != null) ? <Container>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={state.initialValues}
        enableReinitialize={true}
        validateOnMount
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          values,
          touched,
          errors,
          isValid
        }) => (
          <Form onChange={onFormChange} onSubmit={handleSubmit} className="form m-auto">
            <div className="form-header">
              {id != null ? `Cập nhật tin #${id}` : 'Tạo tin mới'}
            </div>
            <div className="form-body">
              <Form.Group controlId="title">
                <Form.Label className="d-block">Tiêu đề</Form.Label>
                <Form.Control
                  name="title"
                  type="text"
                  placeholder="Nhập tiêu đề"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.title && !errors.title}
                  value={values.title}
                  className={touched.title && errors.title ? "error" : undefined}
                />
                {touched.title && errors.title && (
                  <p className="error">{errors.title}</p>
                )}
              </Form.Group>
              <Form.Group controlId="categoryId">
                <Form.Label className="d-block">Chuyên mục</Form.Label>
                <Picker
                  value={values.categoryId!}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={optionsCategory}
                  placeholder="Chọn chuyên mục"
                  name="categoryId"
                />
                {touched.categoryId && errors.categoryId && (
                  <p className="error">{errors.categoryId}</p>
                )}
              </Form.Group>
              <Form.Group controlId="shortDescription">
                <Form.Label className="d-block">Sapo</Form.Label>
                <Form.Control
                  name="shortDescription"
                  type="text"
                  placeholder="Nhập sapo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.shortDescription && !errors.shortDescription}
                  value={values.shortDescription}
                  className={touched.shortDescription && errors.shortDescription ? "error" : undefined}
                />
                {touched.shortDescription && errors.shortDescription && (
                  <p className="error">{errors.shortDescription}</p>
                )}
              </Form.Group>
              <Form.Group controlId="content">
                <Form.Label className="d-block">Nội dung</Form.Label>
                <ContentEditor initialValue={state.initialValues.content} onChange={setFieldValue} onBlur={setFieldTouched} />
                {touched.content && errors.content && (
                  <p className="error">{errors.content}</p>
                )}
              </Form.Group>
              <Form.Group className="text-center d-flex flex-row">
                <Button variant="outline-secondary" className="flex-fill mr-1 py-2" onClick={goBack}>Hủy</Button>
                <Button type="submit" variant="primary" className="flex-fill ml-1 py-2" disabled={!isValid || state.submitting}>Lưu tin</Button>
              </Form.Group>
              <Form.Group>
                {!state.clearError && newsResult && !newsResult.success && newsResult.error && (
                  <p className="error">{newsResult.error.message}</p>
                )}
              </Form.Group>
            </div>
          </Form>
        )}
      </Formik>
    </Container> : null
  )
}

export default NewsFormComp;