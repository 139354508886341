import { ANSWER_DELETE } from 'redux-saga/actions';
import { METHOD } from 'utils/api';
import { watchQuery } from 'utils/sagas';

export function* deleteAnswer() {
    return yield watchQuery(
        ANSWER_DELETE,
        '/answer/{id}',
        METHOD.DELETE
    );
}
