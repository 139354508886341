import { Obj } from 'interface/common';
import { VIDEO_QUERY_LIST } from 'redux-saga/actions';
import {
  VIDEO_QUERY_LIST_SUCCESS,
  VIDEO_QUERY_LIST_FAILED,
  VIDEO_ACTION_SUCCESS,
  VIDEO_ACTION_FAILED,
} from './reducers';

export const queryVideoList = (payload: Obj) => ({
  type: VIDEO_QUERY_LIST,
  response: {
    success: {
      type: VIDEO_QUERY_LIST_SUCCESS,
    },
    failure: {
      type: VIDEO_QUERY_LIST_FAILED,
    },
  },
  payload
});

export const actionVideo = (type: string, payload: FormData) => ({
  type,
  response: {
    success: {
      type: VIDEO_ACTION_SUCCESS,
    },
    failure: {
      type: VIDEO_ACTION_FAILED,
    },
  },
  payload
});