import { EmbedType } from "global";
import { Obj, NotusAttribute } from "interface/common";

export const convert = (data: NotusAttribute[]) => {
    let currentBlock = '';
    let result: string[] = [];
    data.forEach((item) => {
        if (
            item?.attributes &&
            Object.keys(item?.attributes as Obj).includes("block") &&
            currentBlock !== ((item?.attributes as Obj).block as string)
        ) {
            if (currentBlock !== '') { result.splice(result.length - 1, 0, `</${currentBlock}>`) };
            if ((item?.attributes as Obj).block !== 'quote') {
                currentBlock = (item?.attributes as Obj).block as string;
            } else {
                currentBlock = 'blockquote';
            }
            result.splice(result.length - 1, 0, `<${currentBlock}>`);
        }
        writeAttribute(result,item);
        result.push((item?.insert as string).replaceAll('\n','<br>'));
        writeAttribute(result,item, true);
    })
    return result.join('');
};

const writeLinkTag = (value: string, close?: boolean) => {
    return close ? `</a>` : `<a href=${value} />`;
};

const writeBoldTag = (close?: boolean) => {
    return close ? `</b>` : `<b>`;
};

const writeItalicTag = (close?: boolean) => {
    return close ? `</i>` : `<i>`;
};

const writeHeadingTag = (value: string, close?: boolean) => {
    return close ? `</h${value}>` : `<h${value}>`;
};

const writeBlockTag = (value: string, close?: boolean) => {
    if (value === 'quote') {
        return '';
    }
    return close ? `</li>` : `<li>`;
};

const writeEmbedTag = (embed: Obj, close?: boolean) => {
    if (close) {
        return '';
    }
    if (embed?.type === EmbedType.horizontalRule) {
        return "</hr>";
    } else if (embed?.type === EmbedType.image) {
        return `<img src="${embed?.source}">`;
    }
    return '';
};

const writeAttribute = (result: string[], item: NotusAttribute, close?: boolean) => {
    const attributes = item.attributes as Obj;
    if (attributes) {
        if (Object.keys(attributes).includes("b")) {
            result.push(writeBoldTag(close));
        }
        if (Object.keys(attributes).includes("i")) {
            result.push(writeItalicTag(close));
        }
        if (Object.keys(attributes).includes("link")) {
            result.push(
                writeLinkTag((attributes?.link as Obj)?.value as string, close)
            );
        }
        if (Object.keys(attributes).includes("heading")) {
            if (!close) {
                result.splice(
                    result.length - 1,
                    0,
                    writeHeadingTag(attributes?.heading as string, close)
                );
            } else {
                result.push(writeHeadingTag(attributes?.heading as string, close));
            }

        }
        if (Object.keys(attributes as Obj).includes("embed")) {
            result.push(writeEmbedTag(attributes?.embed as Obj, close));
        }
        if (Object.keys(attributes as Obj).includes("block")) {
            if (!close) {
                result.splice(result.length - 1, 0, writeBlockTag(attributes?.block as string, close));
            } else {
                result.push(writeBlockTag(attributes?.block as string, close));
            }
        }
    }
};