import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { State } from 'redux-saga/reducers';
import { changePassword } from './actions';
import { LOGOUT_SUCCESS } from 'router/reducers';
import { Global } from 'global';
import './styles.scss';

const schema = yup.object().shape({
    newPassword: yup.string().required('Tài khoản đăng nhập không được để trống'),
    password: yup.string().required('Mật khẩu không được để trống'),
    repeatNewPassword: yup.string().required('Tài khoản đăng nhập không được để trống').oneOf([yup.ref('newPassword'), null], 'Mật khẩu xác nhận không khớp với mật khẩu mới')});

export interface ChangePasswordForm {
    repeatNewPassword: string;
    newPassword: string;
    password: string;
    username: string;
}

const Profile = () => {
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        submitting: false,
        clearError: false,
    });

    const changePasswordResult = useSelector(
        (state: State) => state.changePasswordResult,
        shallowEqual
    );

    React.useEffect(() => {
        if (changePasswordResult) {
            if (!changePasswordResult.success) {
                setState((prevState) => ({
                    ...prevState,
                    clearError: false,
                    submitting: false,
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    clearError: true,
                    submitting: false,
                }));
                dispatch({
                    type: LOGOUT_SUCCESS
                });
            }
        }
    }, [changePasswordResult]);

    const onFormChange = () => {
        setState((prevState) => ({
            ...prevState,
            clearError: true
        }));
    }

    const handleSubmit = (values: ChangePasswordForm, actions: FormikHelpers<ChangePasswordForm>) => {
        setState((prevState) => ({
            ...prevState,
            submitting: true,
        }));
        dispatch(changePassword(values));
    }
    return (
        <div className="d-flex">
            <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={{
                    password: '',
                    newPassword: '',
                    repeatNewPassword: '',
                    username: Global.me?.username,
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    isValid
                }) => (
                    <Form onChange={onFormChange} onSubmit={handleSubmit} className="form form-login m-auto">
                        <div className="form-header">
                            Đổi mật khẩu
              </div>
                        <div className="form-body">
                            <Form.Group controlId="password">
                                <Form.Label className="d-block">Mật khẩu cũ</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Nhập mật khẩu cũ"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.password && !errors.password}
                                    value={values.password}
                                    className={touched.password && errors.password ? "error" : undefined}
                                />
                                {touched.password && errors.password && (
                                    <p className="error">{errors.password}</p>
                                )}
                            </Form.Group>
                            <Form.Group controlId="newPassword">
                                <Form.Label className="d-block">Mật khẩu mới</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Nhập mật khẩu mới"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.newPassword && !errors.newPassword}
                                    value={values.newPassword}
                                    className={touched.newPassword && errors.newPassword ? "error" : undefined}
                                />
                                {touched.newPassword && errors.newPassword && (
                                    <p className="error">{errors.newPassword}</p>
                                )}
                            </Form.Group>

                            <Form.Group controlId="repeatNewPassword">
                                <Form.Label className="d-block">Nhập lại mật khẩu</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Nhập mật khẩu giống mật khẩu trên"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.repeatNewPassword && !errors.repeatNewPassword}
                                    value={values.repeatNewPassword}
                                    className={touched.repeatNewPassword && errors.repeatNewPassword ? "error" : undefined}
                                />
                                {touched.repeatNewPassword && errors.repeatNewPassword && (
                                    <p className="error">{errors.repeatNewPassword}</p>
                                )}
                            </Form.Group>
                            <Form.Group className="text-center">
                                <Button type="submit" variant="primary" className="btn-login w-100 py-2" disabled={!isValid || state.submitting}>Đổi mật khẩu</Button>
                            </Form.Group>
                            <Form.Group>
                                {!state.clearError && changePasswordResult && !changePasswordResult.success && changePasswordResult.error && (
                                    <p className="error">{changePasswordResult.error.message}</p>
                                )}
                            </Form.Group>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Profile;