import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Container, Form, Image, Modal, Spinner } from 'react-bootstrap';
import { TableChangeType, TableChangeState, ColumnDescription } from 'react-bootstrap-table-next';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import * as yup from 'yup';
import { Obj } from 'interface/common';
import config from 'config';
import { optionsCategory } from 'global';
import DataTable, { DataTableState } from 'component/DataTable';
import Picker from 'component/Picker';
import { State } from 'redux-saga/reducers';
import { formatDateToDisplay } from 'utils/datetime';
import { VIDEO_CREATE, VIDEO_DELETE, VIDEO_UPDATE } from 'redux-saga/actions';
import { actionVideo, queryVideoList } from './actions';
import './styles.scss';

interface VideoState extends DataTableState {
  showFormModal: boolean;
  showVideoModal: boolean;
  showDeleteModal: boolean;
  formData?: VideoForm;
  submitting: boolean;
  clearError: boolean;
  loading: boolean;
  urlPreview: string;
  inViewThumbnail?: boolean;
}

export interface VideoForm {
  id?: number;
  title: string;
  description?: string;
  categoryId: number | null;
  video: File | null;
  videoUrl: string;
  thumbnail: string;
  type: string;
  thumbnailUrl?: string;
}

const blankForm = {
  title: '',
  categoryId: null,
  videoUrl: '',
  description: ''
} as VideoForm;

const schema = yup.object().shape({
  title: yup.string().required('Tiêu đề không được để trống'),
  categoryId: yup.number().typeError('Phải chọn chuyên mục').required('Phải chọn chuyên mục'),
  video: yup.mixed().required('Video không thể để trống')
});

const schema_update = yup.object().shape({
  title: yup.string().required('Tiêu đề không được để trống'),
  categoryId: yup.number().typeError('Phải chọn chuyên mục').required('Phải chọn chuyên mục'),
});

const actionFormatter = (onSelectItem: (row: VideoForm) => void, onPreview: (row: VideoForm, showThumnail?: boolean) => void, onDeleteItem: (row: VideoForm) => void) => ((cell: Obj, row: VideoForm) => {
  return (
    <div className="d-flex justify-content-center">
      <Button variant="outline-primary" className="mr-2" title="Chỉnh sửa tin" onClick={() => onSelectItem(row)}>
        <FaEdit size={20} />
      </Button>
      <Button variant="outline-danger" className="mr-2" title="Xóa tin" onClick={() => onDeleteItem(row)}>
        <FaTrashAlt size={20} />
      </Button>
      <Button variant="outline-success" title="Xem Video" onClick={() => onPreview(row)}>
        <FaEye size={20} />
      </Button>
    </div>
  );
})

const columns = (onSelectItem: (row: VideoForm) => void, onPreview: (row: VideoForm, showThumnail?: boolean) => void, onDeleteItem: (row: VideoForm) => void): ColumnDescription[] => [
  {
    dataField: 'id',
    text: 'No #',
    headerStyle: { width: 60 }
  }, {
    dataField: 'title',
    text: 'Tiêu đề',
    filter: textFilter({
      defaultValue: '',
      delay: 0,
      placeholder: 'Nhập tiêu đề'
    }),
    headerStyle: { width: 200 }
  },
  {
    dataField: "categoryName",
    text: "Chuyên mục",
    filter: selectFilter({
      placeholder: "Tất cả",
      options: optionsCategory,
    }),
    headerStyle: { width: 100 },
  },
  {
    dataField: 'createdAt',
    text: 'Ngày tạo',
    headerStyle: { width: 120 },
    formatter: (row) => {
      return formatDateToDisplay(row, 'dd/MM/yyyy HH:mm:ss', "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as string;
    },
  },
  {
    dataField: 'createdBy',
    text: 'Người tạo',
    headerStyle: { width: 100 }
  },
  {
    dataField: 'action',
    text: '',
    formatter: actionFormatter(onSelectItem, onPreview, onDeleteItem),
    headerStyle: { width: 170 }
  }
];


const Video = () => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<VideoState>({
    page: 1,
    data: [],
    totalSize: 0,
    sizePerPage: config.sizePerPage,
    filters: {},
    showFormModal: false,
    showVideoModal: false,
    showDeleteModal: false,
    submitting: false,
    clearError: false,
    loading: false,
    urlPreview: '',
  });

  const ref = React.useRef<{
    sizePerPage?: number;
    page?: number;
    filters?: Obj;
    type?: TableChangeType;
    newState?: TableChangeState<Obj>;
    categoryFilter?: string;
  }>({});

  const formRef = React.useRef<FormikProps<VideoForm>>();

  const { videoList, videoResult } = useSelector(
    (state: State) => ({
      videoList: state.videoList,
      videoResult: state.videoActionResult,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(queryVideoList({
      limit: state.sizePerPage,
      page: state.page,
      withCount: true
    }));
  }, []);

  React.useEffect(() => {
    if (videoList && videoList.success && videoList.response) {
      const { response } = videoList;
      setState((prevState) => ({
        ...prevState,
        data: response.data as Obj[],
        totalSize: response.totalCount as number,
        page: response.page as number,
        loading: false
      }));
    }
  }, [videoList]);

  React.useEffect(() => {
    if (videoResult) {
      if (!videoResult.success) {
        setState((prevState) => ({
          ...prevState,
          clearError: false,
          submitting: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showFormModal: false,
          showDeleteModal: false,
          clearError: true,
          submitting: false,
          formData: blankForm
        }));

        formRef.current?.resetForm();

        dispatch(queryVideoList({
          limit: state.sizePerPage,
          page: state.page,
          withCount: true,
          ...state.filters
        }));
      }

    }
  }, [videoResult]);

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<Obj>,
    query?: boolean
  ) => {
    let categoryName = ref.current?.categoryFilter;
    if (!query) {
      ref.current.type = type;
      ref.current.sizePerPage = newState.sizePerPage;
      ref.current.page = newState.page;
      ref.current.filters = newState.filters;
      ref.current.type = type;
      ref.current.newState = newState;

      if (
        newState.filters["categoryName"] !== undefined ||
        (newState.filters["categoryName"] === undefined &&
          ref.current?.categoryFilter)
        || type !== 'filter'
      ) {
        categoryName = newState.filters["categoryName"]?.filterVal as string;
        if (ref.current?.categoryFilter !== categoryName || type !== 'filter') {
          setState((prevState) => ({
            ...prevState,
            sizePerPage: newState.sizePerPage,
            page: newState.page,
            filters: newState.filters,
            loading: true,
          }));

          const filters: Obj = {};
          Object.keys(newState.filters).forEach((key) => {
            filters[key] = newState.filters[key].filterVal;
          });

          dispatch(
            queryVideoList({
              limit: newState.sizePerPage,
              page: newState.page,
              withCount: true,
              ...filters,
            })
          );
          ref.current.categoryFilter = categoryName;
        }
      }
    } else {
      if (query) {
        if (ref && ref.current?.newState) {
          setState((prevState) => ({
            ...prevState,
            loading: true,
          }));
          const filters: Obj = {};
          Object.keys(ref.current?.newState?.filters).forEach((key) => {
            filters[key] = ref.current?.newState?.filters[key].filterVal;
          });

          dispatch(
            queryVideoList({
              limit: ref.current?.newState?.sizePerPage,
              page: ref.current?.newState?.page,
              withCount: true,
              ...filters,
            })
          );
        }
      }
    }
  };

  const handleCloseFormModal = () => {
    setState((prevState) => ({
      ...prevState,
      showFormModal: false,
      clearError: true,
      formData: blankForm
    }));

    formRef.current?.resetForm();
  }


  const handleOpenFormModal = () => {
    setState((prevState) => ({
      ...prevState,
      showFormModal: true,
    }));
  }

  const onFormChange = () => {
    setState((prevState) => ({
      ...prevState,
      clearError: true
    }));
  }

  const handleSubmit = (values: VideoForm, actions: FormikHelpers<VideoForm>) => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));
    const form_data = new FormData();

    if (values.id) {
      form_data.append("id", String(values.id));
    }

    form_data.append("title", values.title);
    form_data.append("categoryId", String(values.categoryId));
    form_data.append("description", String(values.description));
    if (values.video) {
      form_data.append("video", values.video);
    }
    if (values.thumbnail) {
      form_data.append("thumbnail", values.thumbnail);
    }

    dispatch(
      actionVideo(state.formData?.id ? VIDEO_UPDATE : VIDEO_CREATE, form_data)
    );
  }

  const onSelectItem = (row: VideoForm) => {
    setState((prevState) => ({
      ...prevState,
      showFormModal: true,
      formData: row
    }));
  }

  const handleCloseVideoModal = () => {
    setState((prevState) => ({
      ...prevState,
      showVideoModal: false,
    }));
  }

  const onPreview = (row: VideoForm, showThumnail?: boolean) => {
    setState((prevState) => ({
      ...prevState,
      showVideoModal: true,
      urlPreview: showThumnail ? row.thumbnailUrl as string : row.videoUrl as string,
      inViewThumbnail: showThumnail
    }));
  }

  const handleCloseDeleteModal = () => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: false
    }));
  }

  const onDeleteItem = (row: VideoForm) => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: true,
      formData: row
    }));
  }

  const onDeleteVideo = () => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));
    dispatch(
      actionVideo(VIDEO_DELETE, (state.formData as unknown) as FormData)
    );
  }

  return (
    <Container>
      <div className="page-header d-flex justify-content-between align-items-center">
        <div className="page-title">Video</div>
        <Button variant="success" onClick={handleOpenFormModal}>
          Thêm Video
        </Button>
      </div>
      <Modal
        size="lg"
        show={state.showVideoModal}
        onHide={handleCloseVideoModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{state.formData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          {!state.inViewThumbnail ? (
            <iframe
              src={state.urlPreview}
              allowFullScreen
              allow="autoplay; encrypted-media"
              width="100%"
              height="310"
              frameBorder="0"
            ></iframe>
          ) : (
              <Image fluid src={state.urlPreview} />
            )}
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        show={state.showDeleteModal}
        onHide={handleCloseDeleteModal}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{state.formData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          Bạn có chắc chắn muốn xóa video này không?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Đóng
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={state.submitting}
            onClick={onDeleteVideo}
          >
            Xóa video
          </Button>
        </Modal.Footer>
      </Modal>
      <DataTable
        data={state.data}
        columns={columns(onSelectItem, onPreview, onDeleteItem)}
        page={state.page}
        totalSize={state.totalSize}
        sizePerPage={state.sizePerPage}
        onTableChange={handleTableChange}
        loading={state.loading}
      />
      <Formik
        validationSchema={state.formData?.id ? schema_update : schema}
        onSubmit={handleSubmit}
        initialValues={state.formData ? state.formData : blankForm}
        enableReinitialize={true}
        validateOnMount
        innerRef={(instance) => {
          formRef.current = instance!;
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          values,
          touched,
          errors,
          isValid,
        }) => {
          const onFileUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              "video",
              event.currentTarget.files ? event.currentTarget.files[0] : null
            );
          };

          const onFileThumbnailUpdate = (
            event: React.ChangeEvent<HTMLInputElement>
          ) => {
            setFieldValue(
              "thumbnail",
              event.currentTarget.files ? event.currentTarget.files[0] : null
            );
          };
          return (
            state.showFormModal && (
              <Form
                id="videoForm"
                onChange={onFormChange}
                onSubmit={handleSubmit}
                className="form m-auto"
              >
                <Modal show={state.showFormModal} onHide={handleCloseFormModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {state.formData?.id != null
                        ? `Cập nhật video #${state.formData?.id}`
                        : "Thêm video mới"}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-body">
                      <Form.Group controlId="title">
                        <Form.Label className="d-block">Tiêu đề</Form.Label>
                        <Form.Control
                          name="title"
                          type="text"
                          placeholder="Nhập tiêu đề"
                          disabled={state.submitting}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.title && !errors.title}
                          value={values.title}
                          className={
                            touched.title && errors.title ? "error" : undefined
                          }
                        />
                        {touched.title && errors.title && (
                          <p className="error">{errors.title}</p>
                        )}
                      </Form.Group>
                      <Form.Group controlId="categoryId">
                        <Form.Label className="d-block">Chuyên mục</Form.Label>
                        <Picker
                          value={values.categoryId!}
                          disabled={state.submitting}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          options={optionsCategory}
                          placeholder="Chọn chuyên mục"
                          name="categoryId"
                        />
                        {touched.categoryId && errors.categoryId && (
                          <p className="error">{errors.categoryId}</p>
                        )}
                      </Form.Group>
                      <Form.Group controlId="description">
                        <Form.Label className="d-block">Mô tả</Form.Label>
                        <Form.Control
                          name="description"
                          as="textarea"
                          placeholder="Nhập mô tả"
                          disabled={state.submitting}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.description && !errors.description}
                          value={values.description}
                          className={
                            touched.description && errors.description
                              ? "error"
                              : undefined
                          }
                        />
                        {touched.description && errors.description && (
                          <p className="error">{errors.description}</p>
                        )}
                      </Form.Group>
                      <Form.Group controlId="video">
                        <Form.Label className="d-block">
                          Chọn tệp video
                        </Form.Label>
                        <Form.Control
                          name="video"
                          type="file"
                          value={undefined}
                          disabled={state.submitting}
                          placeholder="Chọn tệp video"
                          onChange={onFileUpdate}
                          onBlur={handleBlur}
                        />
                        {!state.clearError &&
                          videoResult &&
                          !videoResult.success &&
                          videoResult.error &&
                          videoResult.error.errors &&
                          videoResult.error.errors.findIndex(
                            (value) => value.field === "video"
                          ) >= 0 &&
                          videoResult.error.errors
                            .find((value) => value.field === "video")
                            ?.messages.map((message, index) => (
                              <p className="error" key={index}>
                                {message}
                                <br />
                              </p>
                            ))}
                        {touched.videoUrl && errors.videoUrl && (
                          <p className="error">{errors.videoUrl}</p>
                        )}
                      </Form.Group>
                      <Form.Group controlId="thumbnail">
                        <Form.Label className="d-block">
                          Chọn tệp thumbnail
                        </Form.Label>
                        <Form.Control
                          name="thumbnail"
                          type="file"
                          value={undefined}
                          disabled={state.submitting}
                          placeholder="Chọn tệp thumbnail"
                          onChange={onFileThumbnailUpdate}
                          onBlur={handleBlur}
                        />
                        {!state.clearError &&
                          videoResult &&
                          !videoResult.success &&
                          videoResult.error &&
                          videoResult.error.errors &&
                          videoResult.error.errors.findIndex(
                            (value) => value.field === "thumbnail"
                          ) >= 0 &&
                          videoResult.error.errors
                            .find((value) => value.field === "thumbnail")
                            ?.messages.map((message, index) => (
                              <p className="error" key={index}>
                                {message}
                                <br />
                              </p>
                            ))}
                        {touched.thumbnail && errors.thumbnail && (
                          <p className="error">{errors.thumbnail}</p>
                        )}
                      </Form.Group>
                      {state.formData?.id && (
                        <>
                          <Form.Group>
                            <a
                              rel="noreferrer"
                              href="#"
                              onClick={() => onPreview(state.formData!)}
                            >
                              Xem video
                            </a>
                          </Form.Group>
                          <Form.Group>
                            <a
                              rel="noreferrer"
                              href="#"
                              onClick={() => onPreview(state.formData!, true)}
                            >
                              Xem thumbnail
                            </a>
                          </Form.Group>
                        </>
                      )}
                      <Form.Group>
                        {!state.clearError &&
                          videoResult &&
                          !videoResult.success &&
                          videoResult.error && (
                            <p className="error">{videoResult.error.message}</p>
                          )}
                      </Form.Group>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleCloseFormModal}
                      disabled={state.submitting}
                    >
                      Đóng
                    </Button>
                    <Button
                      type="submit"
                      form="videoForm"
                      variant="primary"
                      disabled={!isValid || state.submitting}
                    >
                      {state.submitting ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Đang lưu video
                        </>
                      ) : (
                          <>Lưu video</>
                        )}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )
          );
        }}
      </Formik>
    </Container>
  );
}

export default Video;