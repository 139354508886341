import * as React from 'react';
import { Image, Row, Col, Button, Modal } from 'react-bootstrap';
import ReactHtmlParser from "react-html-parser";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import config from "config";
import { NotusAttribute, Obj } from 'interface/common';
import { formatDateToDisplay } from "utils/datetime";
import { convert } from "utils/notus";
import { ANSWER_DELETE } from "redux-saga/actions";
import { actionAnswer } from "./actions";
import './styles.scss';

interface AnswerState {
  showDeleteAnswerModal: boolean;
  formData?: AnswersForm;
  submitting: boolean;
  clearError: boolean;
  loading: boolean;
}

export interface AnswersForm {
  id?: number;
  title: string;
  categoryId: number | null;
  document: File | null;
  documentUrl?: string;
}

export interface AnswersProps {
  id: number;
  answer: Obj;
}

const Answers = (props: AnswersProps) => {
  const { answer, id } = props;
  const dispatch = useDispatch();
  const [state, setState] = React.useState<AnswerState>({
    showDeleteAnswerModal: false,
    submitting: false,
    clearError: false,
    loading: false,
  });


  const onDeleteItem = () => {
    setState((prevState) => ({
      ...prevState,
      showDeleteAnswerModal: true,
    }));
  };

  const onDeleteAnswer = () => {
    dispatch(actionAnswer(ANSWER_DELETE, (answer as unknown) as FormData));
  };

  const handleCloseDeleteModal = () => {
    setState((prevState) => ({
      ...prevState,
      showDeleteAnswerModal: false,
    }));
  };
  return (
    <div>
      <Modal
        size="sm"
        show={state.showDeleteAnswerModal}
        onHide={handleCloseDeleteModal}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{state.formData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          Bạn có chắc chắn muốn xóa câu trả lời này không?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Đóng
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={onDeleteAnswer}
          >
            Xóa câu trả lời
          </Button>
        </Modal.Footer>
      </Modal>
      <Row className="pb-2 mt-2 border-bottom">
        <hr />
        <Col xs={12} md={12}>
          <Row>
            <Col xs={1} md={1}>
              <Image
                className="Avatar"
                src={
                  ((answer.createdBy as Obj)?.userInfo as Obj)?.avatarUrl
                    ? (((answer.createdBy as Obj)?.userInfo as Obj)
                        ?.avatarUrl as string)
                    : `/noAvatar.png`
                }
                roundedCircle
              />
            </Col>
            <Col xs={11} md={11}>
              <span className="CreateBy">{answer.createdByUser} </span>
              <span className="Time">
                {formatDateToDisplay(
                  answer.createdAt as string,
                  "dd/MM/yyyy HH:mm:ss",
                  "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
                )}
              </span>
            </Col>
            <Col xl={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }}>
              {ReactHtmlParser(convert(answer?.answer as NotusAttribute[]))}
            </Col>
            <Col xl={1} md={1}>
              <Button onClick={onDeleteItem} variant="link">
                <ImCross className="Delete" />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Answers;