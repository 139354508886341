import { Obj } from 'interface/common';
import { NEWS_QUERY_LIST } from 'redux-saga/actions';
import {
  NEWS_QUERY_LIST_SUCCESS,
  NEWS_QUERY_LIST_FAILED,
} from './reducers';

export const queryNewsList = (payload: Obj) => ({
  type: NEWS_QUERY_LIST,
  response: {
    success: {
      type: NEWS_QUERY_LIST_SUCCESS,
    },
    failure: {
      type: NEWS_QUERY_LIST_FAILED,
    },
  },
  payload
});