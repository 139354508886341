import Helmet from 'react-helmet';

const AppTitle = ({ title }: { title?: string }) => {
  const defaultTitle = 'Quản trị Khuyến Nông';
  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
    </Helmet>
  );
};

export default AppTitle;
