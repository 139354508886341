import { AUTH_VERIFY_TOKEN } from 'redux-saga/actions';
import { VERIFY_TOKEN_FAILED, VERIFY_TOKEN_SUCCESS } from './reducers';

export const verifyToken = () => ({
  type: AUTH_VERIFY_TOKEN,
  response: {
    success: {
      type: VERIFY_TOKEN_SUCCESS,
    },
    failure: {
      type: VERIFY_TOKEN_FAILED,
    },
  },
});