import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Button, Container, Modal, Form, Accordion, Card } from 'react-bootstrap';
import { TableChangeType, TableChangeState, ColumnDescription } from 'react-bootstrap-table-next';
import { textFilter } from 'react-bootstrap-table2-filter';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Obj } from 'interface/common';
import config from 'config';
import DataTable, { DataTableState } from 'component/DataTable';
import { PERMISSION } from 'constant';
import { FaEdit, FaArrowRight, FaArrowDown } from 'react-icons/fa';
import { queryUserList } from 'screen/User/actions';
import { actionAdmin } from './actions';
import { ADMIN_CREATE, ADMIN_UPDATE } from 'redux-saga/actions';
import { formatDateToDisplay } from 'utils/datetime';
import { State } from 'redux-saga/reducers';
import './styles.scss';


export interface UserForm {
  id?: number;
  username: string;
  password?: string;
  name?: string;
  phoneNumber?: string;
  address?: string;
  permissions: number[];
  enable: boolean;
  password_confirm: string;
}

interface UserState extends DataTableState {
  showFormModal: boolean;
  showPreviewModal: boolean;
  showDeleteModal: boolean;
  formData?: UserForm;
  submitting: boolean;
  clearError: boolean;
  loading: boolean;
  showChangePassword: boolean;
}

const blankForm = {
  username: '',
  password: '',
  password_confirm: ''
} as UserForm;

const isAdmin = (permissions: number[]) => {
  return permissions.indexOf(PERMISSION.ADMIN) > -1;
}
const actionFormatter = (onSelectItem: (row: UserForm) => void) => ((cell: Obj, row: UserForm) => {
  return (
    <div className="d-flex justify-content-center">
      <Button variant="outline-primary" className="mr-2" title="Chỉnh sửa thông tin" onClick={() => onSelectItem(row)}>
        <FaEdit size={20} />
      </Button>
    </div>
  );
})

const schema_create = yup.object().shape({
  username: yup.string().required('Tên đăng nhập không được để trống'),
  password: yup.string().required('Mật khẩu không được để trống'),
})

const schema_update = yup.object().shape({
  username: yup.string().required("Tên đăng nhập không được để trống"),
  password: yup
    .string()
    .min(6, "Mật khẩu quá ngắn - phải dài hơn 6 ký tự"),
  password_confirm: yup
    .string()
    .test("passwords-match", "Mật khẩu phải trùng nhau", function (value) {
      return this.parent.password === value;
    }),
});

const columns = (onSelectItem: (row: UserForm) => void): ColumnDescription[] => [
  {
    dataField: 'id',
    text: 'No #',
    headerStyle: { width: 60 }
  },
  {
    dataField: 'username',
    text: 'Tên đăng nhập',
    filter: textFilter({
      defaultValue: '',
      delay: 0,
      placeholder: 'Nhập tên tài khoản'
    }),
    headerStyle: { width: 150 }
  },
  {
    dataField: 'createdAt',
    text: 'Ngày tạo',
    headerStyle: { width: 150 },
    formatter: (row) => {
      return formatDateToDisplay(row, 'dd/MM/yyyy HH:mm:ss', "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as string;
    },
  },
  {
    dataField: 'enable',
    text: 'Trạng thái',
    headerStyle: { width: 150 },
    formatter: (row) => {
      return row === true ? 'Còn hoạt động' : 'Bị khóa';
    },
  },
  {
    dataField: 'action',
    text: '',
    formatter: actionFormatter(onSelectItem),
    headerStyle: { width: 80 }
  },
];

const Admin = () => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<UserState>({
    page: 1,
    data: [],
    totalSize: 0,
    sizePerPage: config.sizePerPage,
    filters: {},
    showFormModal: false,
    showPreviewModal: false,
    showDeleteModal: false,
    submitting: false,
    clearError: false,
    loading: false,
    showChangePassword: false
  });

  const ref = React.useRef<{
    sizePerPage?: number;
    page?: number;
    filters?: Obj;
    type?: TableChangeType;
    newState?: TableChangeState<Obj>;
  }>({});

  const formRef = React.useRef<FormikProps<UserForm>>();

  const { userList, adminActionResult } = useSelector(
    (state: State) => ({ userList: state.userList, adminActionResult: state.adminActionResult }),
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(queryUserList({
      limit: state.sizePerPage,
      page: state.page,
      withCount: true,
      permission: PERMISSION.ADMIN
    }));
  }, []);

  React.useEffect(() => {
    if (userList && userList.success && userList.response) {
      const { response } = userList;
      setState((prevState) => ({
        ...prevState,
        data: response.data as Obj[],
        totalSize: response.totalCount as number,
        page: response.page as number,
        loading: false
      }));
    }
  }, [userList]);

  React.useEffect(() => {
    if (adminActionResult) {
      if (!adminActionResult.success) {
        setState((prevState) => ({
          ...prevState,
          clearError: false,
          submitting: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showFormModal: false,
          showDeleteModal: false,
          clearError: true,
          submitting: false,
          showChangePassword: false,
          formData: blankForm,
        }));

        dispatch(queryUserList({
          limit: state.sizePerPage,
          page: state.page,
          withCount: true,
          permission: PERMISSION.ADMIN,
          ...state.filters
        }));
      }

    }
  }, [adminActionResult]);

  const onSelectItem = (row: UserForm) => {
    setState((prevState) => ({
      ...prevState,
      showFormModal: true,
      formData: row
    }));
  }

  const onDisableItem = (event: React.FormEvent, row: UserForm) => {
    const checked = (event.target as unknown as Obj).checked;
    dispatch(actionAdmin(ADMIN_UPDATE, { id: row.id, enable: checked }))
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: true,
      formData: row
    }));
  }

  const handleSubmit = (values: UserForm, actions: FormikHelpers<UserForm>) => {
    if (state.formData?.id) {
      dispatch(actionAdmin(ADMIN_UPDATE, (values as unknown) as Obj));
    } else {
      dispatch(actionAdmin(ADMIN_CREATE, (values as unknown) as Obj));
    }

    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));
  }

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<Obj>,
    query?: boolean
  ) => {
    if (!query) {
      ref.current.type = type;
      ref.current.sizePerPage = newState.sizePerPage;
      ref.current.page = newState.page;
      ref.current.filters = newState.filters;
      ref.current.type = type;
      ref.current.newState = newState;

      if (type !== 'filter') {
        setState((prevState) => ({
          ...prevState,
          sizePerPage: newState.sizePerPage,
          page: newState.page,
          filters: newState.filters,
          loading: true,
        }));

        const filters: Obj = {};
        Object.keys(ref.current?.newState?.filters).forEach((key) => {
          filters[key] = ref.current?.newState?.filters[key].filterVal;
        });

        dispatch(
          queryUserList({
            limit: ref.current?.newState?.sizePerPage,
            page: ref.current?.newState?.page,
            permission: PERMISSION.ADMIN,
            withCount: true,
            ...filters,
          })
        );
      }
    } else {
      if (ref && ref.current?.newState) {
        setState((prevState) => ({
          ...prevState,
          page: newState.page,
          filters: newState.filters,
          loading: true,
        }));

        const filters: Obj = {};
        Object.keys(ref.current?.newState?.filters).forEach((key) => {
          filters[key] = ref.current?.newState?.filters[key].filterVal;
        });

        dispatch(
          queryUserList({
            limit: ref.current?.newState?.sizePerPage,
            page: ref.current?.newState?.page,
            permission: PERMISSION.ADMIN,
            withCount: true,
            ...filters,
          })
        );
      }
    }
  };
  const onFormChange = () => {
    setState((prevState) => ({
      ...prevState,
      clearError: true
    }));
  }

  const handleOpenFormModal = () => {
    setState((prevState) => ({
      ...prevState,
      showFormModal: true,
    }));
  }

  const handleCloseFormModal = () => {
    setState((prevState) => ({
      ...prevState,
      showFormModal: false,
      clearError: true,
      showChangePassword: false,
      formData: blankForm,
    }));

    formRef.current?.resetForm();
  }

  const showChangePassword = () => {
    setState((prevState) => ({
      ...prevState,
      showChangePassword: !prevState.showChangePassword
    }));
  }

  return (
    <Container>
      <div className="page-header d-flex justify-content-between align-items-center">
        <div className="page-title">Danh sách quản trị viên</div>
        <Button variant="success" onClick={handleOpenFormModal}>
          Thêm quản trị viên
          </Button>
      </div>
      <DataTable
        data={state.data}
        columns={columns(onSelectItem)}
        page={state.page}
        totalSize={state.totalSize}
        sizePerPage={state.sizePerPage}
        onTableChange={handleTableChange}
        loading={state.loading}
      />

      <Formik
        validationSchema={
          state.formData?.id == null ? schema_create : schema_update
        }
        onSubmit={handleSubmit}
        initialValues={state.formData ? state.formData : blankForm}
        enableReinitialize={true}
        validateOnMount
        innerRef={(instance) => {
          formRef.current = instance!;
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          values,
          touched,
          errors,
          isValid,
        }) =>
          state.showFormModal && (
            <Form
              id="adminForm"
              onChange={onFormChange}
              onSubmit={handleSubmit}
              className="form m-auto"
            >
              <Modal show={state.showFormModal} onHide={handleCloseFormModal}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {state.formData?.id != null
                      ? `Cập nhật tài khoản #${state.formData?.id}`
                      : "Thêm tài khoản"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-body">
                    <Form.Group controlId="username">
                      <Form.Label className="d-block">Tài khoản</Form.Label>
                      <Form.Control
                        name="username"
                        type="text"
                        placeholder="Nhập tài khoản"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.username && !errors.username}
                        value={values.username}
                        className={
                          touched.username && errors.username
                            ? "error"
                            : undefined
                        }
                      />
                      {touched.username && errors.username && (
                        <p className="error">{errors.username}</p>
                      )}
                    </Form.Group>
                    {state.formData?.id == null && (
                      <Form.Group controlId="password">
                        <Form.Label className="d-block">Mật khẩu</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Nhập mật khẩu"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.password && !errors.password}
                          value={values.password}
                          className={
                            touched.password && errors.password
                              ? "error"
                              : undefined
                          }
                        />
                        {touched.password && errors.password && (
                          <p className="error">{errors.password}</p>
                        )}
                      </Form.Group>
                    )}
                    {state.formData?.id != null && (
                      <Form.Group controlId="password">
                        <div className="d-flex justify-content-left">
                          <Form.Check
                            value={
                              isAdmin(state.formData?.permissions) ? 1 : 0
                            }
                            checked={
                              isAdmin(state.formData?.permissions) &&
                              state.formData?.enable
                            }
                            onChange={(
                              event: React.FormEvent<HTMLInputElement>
                            ) => onDisableItem(event, state.formData!)}
                          />
                          <Form.Label className="d-block">
                            Quyền quản trị viên
                            </Form.Label>
                        </div>
                      </Form.Group>
                    )}
                    {state.formData?.id != null && (
                      <Accordion defaultActiveKey="0">
                        <Card>
                          <Accordion.Toggle
                            as={Card.Header}
                            onClick={showChangePassword}
                            eventKey="1"
                          >
                            Đổi mật khẩu{" "}
                            {state.showChangePassword ? (
                              <FaArrowDown className="float-right" />
                            ) : (
                                <FaArrowRight className="float-right" />
                              )}
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <Form.Group controlId="password">
                                <Form.Label className="d-block">
                                  Mật khẩu mới
                                  </Form.Label>
                                <Form.Control
                                  name="password"
                                  type="password"
                                  placeholder="Nhập mật khẩu"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isValid={
                                    touched.password && !errors.password
                                  }
                                  value={values.password}
                                  className={
                                    touched.password &&
                                      errors.password
                                      ? "error"
                                      : undefined
                                  }
                                />
                                {touched.password &&
                                  errors.password && (
                                    <p className="error">
                                      {errors.password}
                                    </p>
                                  )}
                              </Form.Group>
                              <Form.Group controlId="password_confirm">
                                <Form.Label className="d-block">
                                  Mật khẩu mới
                                  </Form.Label>
                                <Form.Control
                                  name="password_confirm"
                                  type="password"
                                  placeholder="Nhập lại mật khẩu mới"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isValid={
                                    touched.password && !errors.password_confirm
                                  }
                                  value={values.password_confirm}
                                  className={
                                    touched.password_confirm &&
                                      errors.password_confirm
                                      ? "error"
                                      : undefined
                                  }
                                />
                                {touched.password_confirm &&
                                  errors.password_confirm && (
                                    <p className="error">
                                      {errors.password_confirm}
                                    </p>
                                  )}
                              </Form.Group>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    )}
                    <Form.Group>
                      {!state.clearError &&
                        adminActionResult &&
                        !adminActionResult.success &&
                        adminActionResult.error && (
                          <p className="error">
                            {adminActionResult.error.message}
                          </p>
                        )}
                    </Form.Group>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseFormModal}>
                    Đóng
                    </Button>
                  <Button
                    type="submit"
                    form="adminForm"
                    variant="primary"
                    {...{
                      disabled: !isValid || state.submitting,
                    }}
                  >
                    Lưu quản trị viên
                    </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )
        }
      </Formik>
    </Container>
  );
}

export default Admin;