import { Obj } from 'interface/common';
import { DOCUMENT_QUERY_LIST } from 'redux-saga/actions';
import { 
  DOCUMENT_QUERY_LIST_SUCCESS, 
  DOCUMENT_QUERY_LIST_FAILED, 
  DOCUMENT_ACTION_SUCCESS, 
  DOCUMENT_ACTION_FAILED 
} from './reducers';

export const queryDocumentList = (payload: Obj) => ({
  type: DOCUMENT_QUERY_LIST,
  response: {
    success: {
      type: DOCUMENT_QUERY_LIST_SUCCESS,
    },
    failure: {
      type: DOCUMENT_QUERY_LIST_FAILED,
    },
  },
  payload
});

export const actionDocument = (type: string, payload: FormData) => ({
  type,
  response: {
    success: {
      type: DOCUMENT_ACTION_SUCCESS,
    },
    failure: {
      type: DOCUMENT_ACTION_FAILED,
    },
  },
  payload
});