import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { textFilter } from 'react-bootstrap-table2-filter';
import {
  TableChangeType,
  TableChangeState,
  ColumnDescription,
} from 'react-bootstrap-table-next';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';
import { Obj } from 'interface/common';
import config from 'config';
import { formatDateToDisplay } from 'utils/datetime';
import DataTable, { DataTableState } from 'component/DataTable';
import Farmer from 'screen/Farmer';
import { FaListUl, FaTrashAlt } from 'react-icons/fa';
import { PERMISSION } from 'constant';
import { actionUser, queryUserList } from './actions';
import { USER_UPDATE, EXPERT_CREATE } from 'redux-saga/actions';
import { State } from 'redux-saga/reducers';
import './styles.scss';

interface UserState extends DataTableState {
  showFormModal: boolean;
  showPreviewModal: boolean;
  showDeleteModal: boolean;
  formData?: UserForm;
  submitting: boolean;
  clearError: boolean;
  loading: boolean;
  showModalFarmer: boolean;
}

export interface UserForm {
  id?: number;
  username: string;
  password?: string;
  name?: string;
  phoneNumber?: string;
  address?: string;
  permissions: number[];
  enable: boolean;
  city?: string;
}

const blankForm = {
  username: "",
  phoneNumber: "",
  name: "",
  enable: false,
  city: "",
  permissions: [] as number[],
} as UserForm;

const isExpert = (permissions: number[]) => {
  return permissions.indexOf(PERMISSION.EXPERT) > -1;
};

const schema = yup.object().shape({
  name: yup.string().required("Tên chuyên viên không được để trống"),
  phoneNumber: yup.string().required("Số điện thoại không được để trống"),
});

const actionFormatter = (
  onSelectItem: (
    event: React.FormEvent<HTMLInputElement>,
    row: UserForm
  ) => void
) => (cell: Obj, row: UserForm) => {
  return (
    <div className="d-flex justify-content-center">
      <Form.Check
        value={isExpert(row.permissions) ? 1 : 0}
        checked={isExpert(row.permissions)}
        onChange={(event: React.FormEvent<HTMLInputElement>) =>
          onSelectItem(event, row)
        }
      />
    </div>
  );
};

const actionDelete = (onDeleteItem: (row: UserForm) => void) => (
  cell: Obj,
  row: UserForm
) => {
  return (
    <div className="d-flex justify-content-center">
      {isExpert(row.permissions) ? (
        <Button
          variant="outline-danger"
          className="mr-2"
          title="Xóa tin"
          onClick={() => onDeleteItem(row)}
        >
          <FaTrashAlt size={20} />
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};
const showFarmer = (onSelectItem: (row: UserForm) => void) => (
  cell: Obj,
  row: UserForm
) => {
  return (
    <div className="d-flex justify-content-center">
      {isExpert(row.permissions) ? (
        <div className={"d-flex align-items-center cursor-pointer"}>
          <Button
            variant="light"
            title="Xem thông tin nông hộ"
            className="p-0"
            onClick={() => onSelectItem(row)}
          >
            <FaListUl />
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const columns = (
  onSelectItem: (
    event: React.FormEvent<HTMLInputElement>,
    row: UserForm
  ) => void,
  onSelectItemUser: (row: UserForm) => void,
  onDeleteItemUser: (row: UserForm) => void
): ColumnDescription[] => [
  {
    dataField: 'id',
    text: 'No #',
    headerStyle: { width: 60 },
  },
  {
    dataField: 'userInfo.name',
    text: 'Tên',
    filter: textFilter({
      defaultValue: '',
      placeholder: 'Nhập tên',
      delay: 0,
    }),
    formatter: (value, row) => {
      if (value === 'Default') {
        return row.username;
      }
      return value;
    },
    headerStyle: { width: 100 },
  },
  {
    dataField: 'userInfo.phoneNumber',
    text: 'Số điện thoại',
    filter: textFilter({
      defaultValue: '',
      placeholder: 'Nhập số điện thoại',
      delay: 0,
    }),
    headerStyle: { width: 150 },
  },
  {
    dataField: 'userInfo.address',
    text: 'Địa chỉ',
    filter: textFilter({
      defaultValue: '',
      placeholder: 'Nhập địa chỉ',
      delay: 0,
    }),
    headerStyle: { width: 200 },
  },
  {
    dataField: 'createdAt',
    text: 'Ngày tạo',
    formatter: (row) => {
      return formatDateToDisplay(
        row,
        "dd/MM/yyyy HH:mm:ss",
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      ) as string;
    },
    headerStyle: { width: 120 },
  },
  {
    dataField: 'action',
    text: 'Chuyên viên',
    formatter: actionFormatter(onSelectItem),
    headerStyle: { width: 80 },
  },
  {
    dataField: 'userInfo.expertId',
    text: 'Mã Chuyên viên',
    headerStyle: { width: 80 },
  },
  {
    dataField: 'showList',
    text: 'Nông hộ',
    formatter: showFarmer(onSelectItemUser),
    headerStyle: { width: 80 },
  },
  {
    dataField: 'delete',
    text: 'Xóa chuyên viên',
    formatter: actionDelete(onDeleteItemUser),
    headerStyle: { width: 80 },
  },
];

const User = () => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<UserState>({
    page: 1,
    data: [],
    totalSize: 0,
    sizePerPage: config.sizePerPage,
    filters: {},
    showFormModal: false,
    showPreviewModal: false,
    showDeleteModal: false,
    submitting: false,
    clearError: false,
    loading: false,
    showModalFarmer: false,
  });

  const ref = React.useRef<{
    sizePerPage?: number;
    page?: number;
    filters?: Obj;
    type?: TableChangeType;
    newState?: TableChangeState<Obj>;
  }>({});

  const formRef = React.useRef<FormikProps<UserForm>>();

  const { userList, userActionResult } = useSelector(
    (state: State) => ({
      userList: state.userList,
      userActionResult: state.userActionResult,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(
      queryUserList({
        limit: state.sizePerPage,
        page: state.page,
        withCount: true,
      })
    );
  }, []);

  React.useEffect(() => {
    if (userList && userList.success && userList.response) {
      const { response } = userList;
      setState((prevState) => ({
        ...prevState,
        data: response.data as Obj[],
        totalSize: response.totalCount as number,
        page: response.page as number,
        loading: false,
      }));
    }
  }, [userList]);

  React.useEffect(() => {
    if (userActionResult) {
      if (!userActionResult.success) {
        setState((prevState) => ({
          ...prevState,
          clearError: false,
          submitting: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showFormModal: false,
          showDeleteModal: false,
          clearError: true,
          submitting: false,
        }));

        dispatch(
          queryUserList({
            limit: state.sizePerPage,
            page: state.page,
            withCount: true,
            ...state.filters,
          })
        );
      }
    }
  }, [userActionResult]);

  const onSelectItem = (event: React.FormEvent, row: UserForm) => {
    const checked = ((event.target as unknown) as Obj).checked;
    if (checked) {
      row.permissions.push(PERMISSION.EXPERT);
      dispatch(
        actionUser(USER_UPDATE, { id: row.id, permissions: row.permissions })
      );
    } else {
      const permissions = row.permissions.filter(
        (number) => number !== PERMISSION.EXPERT
      );
      dispatch(actionUser(USER_UPDATE, { id: row.id, permissions }));
    }
    setState((prevState) => ({
      ...prevState,
      formData: row,
    }));
  };

  const onSelectItemUser = (row: UserForm) => {
    setState((prevState) => ({
      ...prevState,
      showModalFarmer: true,
      formData: row,
    }));
  };

  const onDeleteItemUser = (row: UserForm) => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: true,
      formData: row,
    }));
  };

  const handleCloseFormModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModalFarmer: false,
      showFormModal: false,
      clearError: true,
      formData: blankForm,
    }));
  };

  const handleOpenFormModal = () => {
    setState((prevState) => ({
      ...prevState,
      showFormModal: true,
    }));
  };

  const handleCloseDeleteModal = () => {
    setState((prevState) => ({
      ...prevState,
      clearError: true,
      showDeleteModal: false,
    }));
  };

  const onFormChange = () => {
    setState((prevState) => ({
      ...prevState,
      clearError: true,
    }));
  };

  const handleSubmit = (values: UserForm, actions: FormikHelpers<UserForm>) => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));

    dispatch(actionUser(EXPERT_CREATE, (values as unknown) as Obj));
  };

  const handleSubmitDelete = () => {
    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));

    dispatch(
      actionUser(USER_UPDATE, {
        id: state.formData?.id,
        isDeleted: true,
      })
    );
  };

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<Obj>,
    query?: boolean
  ) => {
    if (!query) {
      ref.current.type = type;
      ref.current.sizePerPage = newState.sizePerPage;
      ref.current.page = newState.page;
      ref.current.filters = newState.filters;
      ref.current.type = type;
      ref.current.newState = newState;

      if (type !== "filter") {
        setState((prevState) => ({
          ...prevState,
          sizePerPage: newState.sizePerPage,
          page: newState.page,
          filters: newState.filters,
          loading: true,
        }));
        const filters: Obj = {};
        Object.keys(ref.current?.newState?.filters).forEach((key) => {
          filters[key] = ref.current?.newState?.filters[key].filterVal;
        });

        filters.name = filters["userInfo.name"] || "";
        filters.phoneNumber = filters["userInfo.phoneNumber"] || "";
        filters.address = filters["userInfo.address"] || "";

        dispatch(
          queryUserList({
            limit: ref.current?.newState?.sizePerPage,
            page: ref.current?.newState?.page,
            withCount: true,
            ...filters,
          })
        );
      }
    } else {
      if (query) {
        if (ref && ref.current?.newState) {
          setState((prevState) => ({
            ...prevState,
            loading: true,
          }));
          const filters: Obj = {};
          Object.keys(ref.current?.newState?.filters).forEach((key) => {
            filters[key] = ref.current?.newState?.filters[key].filterVal;
          });

          filters.name = filters["userInfo.name"] || "";
          filters.phoneNumber = filters["userInfo.phoneNumber"] || "";
          filters.address = filters["userInfo.address"] || "";

          dispatch(
            queryUserList({
              limit: ref.current?.newState?.sizePerPage,
              page: ref.current?.newState?.page,
              withCount: true,
              ...filters,
            })
          );
        }
      }
    }
  };

  return (
    <Container>
      <div className="page-header d-flex justify-content-between align-items-center">
        <div className="page-title">Danh sách người dùng</div>
        <Button variant="success" onClick={handleOpenFormModal}>
          Thêm chuyên viên
        </Button>
      </div>

      {state.showModalFarmer && (
        <Modal
          show={state.showModalFarmer}
          onHide={handleCloseFormModal}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {state.formData?.id != null
                ? `Danh sách nông hộ của người dùng ${
                    (((state.formData as unknown) as Obj)?.userInfo as Obj)
                      ?.name
                  }`
                : "Tạo văn bản mới"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Farmer id={state.formData?.id} inUser={false} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseFormModal}>
              Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={state.formData ? state.formData : blankForm}
        enableReinitialize={true}
        validateOnMount
        innerRef={(instance) => {
          formRef.current = instance!;
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          values,
          touched,
          errors,
          isValid,
        }) =>
          state.showFormModal && (
            <Form
              id="userForm"
              onChange={onFormChange}
              onSubmit={handleSubmit}
              className="form m-auto"
            >
              <Modal show={state.showFormModal} onHide={handleCloseFormModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Thêm chuyên viên mới</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-body">
                    <Form.Group controlId="name">
                      <Form.Label className="d-block">
                        Tên chuyên viên
                      </Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        placeholder="Nhập tên chuyên viên"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.name && !errors.name}
                        value={values.name!}
                        className={
                          touched.name && errors.name ? "error" : undefined
                        }
                      />
                      {touched.name && errors.name && (
                        <p className="error">{errors.name}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="phoneNumber">
                      <Form.Label className="d-block">Số điện thoại</Form.Label>
                      <Form.Control
                        name="phoneNumber"
                        type="text"
                        placeholder="Nhập số diện thoại"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.phoneNumber && !errors.phoneNumber}
                        value={values.phoneNumber!}
                        className={
                          touched.phoneNumber && errors.phoneNumber
                            ? "error"
                            : undefined
                        }
                      />
                      {touched.phoneNumber && errors.phoneNumber && (
                        <p className="error">{errors.phoneNumber}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="city">
                      <Form.Label className="d-block">Tỉnh thành phố</Form.Label>
                      <Form.Control
                        name="city"
                        type="text"
                        placeholder="Nhập tỉnh thành phố"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.city && !errors.city}
                        value={values.city!}
                        className={
                          touched.city && errors.city ? "error" : undefined
                        }
                      />
                      {touched.city && errors.city && (
                        <p className="error">{errors.city}</p>
                      )}
                    </Form.Group>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCloseFormModal}
                      >
                        Đóng
                      </Button>
                      <Button
                        type="submit"
                        form="userForm"
                        variant="primary"
                        disabled={!isValid || state.submitting}
                      >
                        Lưu chuyên viên
                      </Button>
                    </Modal.Footer>
                  </div>
                </Modal.Body>
              </Modal>
            </Form>
          )
        }
      </Formik>
      <Formik
        onSubmit={handleSubmitDelete}
        initialValues={state.formData!}
        enableReinitialize={true}
        validateOnMount
      >
        {({ handleSubmit }) =>
          state.showDeleteModal && (
            <Form id="newsForm" onSubmit={handleSubmit} className="form m-auto">
              <Modal
                size="sm"
                show={state.showDeleteModal}
                onHide={handleCloseDeleteModal}
                centered={true}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Xóa chuyên viên</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                  Bạn có chắc chắn muốn xóa chuyên viên này không?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseDeleteModal}>
                    Đóng
                  </Button>
                  <Button
                    type="submit"
                    form="newsForm"
                    variant="primary"
                    disabled={state.submitting}
                  >
                    Xóa chuyên viên
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )
        }
      </Formik>
      <DataTable
        data={state.data}
        columns={columns(onSelectItem, onSelectItemUser, onDeleteItemUser)}
        page={state.page}
        totalSize={state.totalSize}
        sizePerPage={state.sizePerPage}
        onTableChange={handleTableChange}
        loading={state.loading}
      />
    </Container>
  );
};

export default User;
