import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Container, Spinner } from 'react-bootstrap';
import { TableChangeType, TableChangeState, ColumnDescription } from 'react-bootstrap-table-next';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { Obj } from 'interface/common';
import { formatDateToDisplay } from 'utils/datetime';
import config from 'config';
import { optionsCategory } from 'global';
import DataTable, { DataTableState } from 'component/DataTable';
import { FaFileExcel } from "react-icons/fa";
import { State } from 'redux-saga/reducers';
import { FARMER_EXPORT_LIST_CLEAR } from './reducers';
import { exportFarmerList, queryFarmerList } from "./actions";
import './styles.scss';

interface FarmerState extends DataTableState {
  formData?: FarmerForm;
  loading: boolean;
  loadingExport: boolean;
}

export interface FarmerForm {
  id?: number;
  title: string;
  categoryId: number | null;
  document: File | null;
  documentUrl?: string;
}

export interface FarmerProps {
  id?: number;
  inUser?: boolean;
}

const columns = (inUser?: boolean): ColumnDescription[] => {
  if (!inUser) {
    return [
      {
        dataField: "id",
        text: "No #",
        headerStyle: { width: 60 },
      },
      {
        dataField: "name",
        text: "Họ và tên",
        filter: textFilter({
          defaultValue: "",
          delay: 0,
          placeholder: "Nhập họ và tên",
        }),
        headerStyle: { width: 200 },
      },
      {
        dataField: "project",
        text: "Thuộc dự án",
        filter: textFilter({
          defaultValue: "",
          delay: 0,
          placeholder: "Nhập dự án",
        }),
        headerStyle: { width: 200 },
      },
      {
        dataField: "expertName",
        text: "Chuyên viên",
        filter: textFilter({
          defaultValue: "",
          delay: 0,
          placeholder: "Nhập tên chuyên viên",
        }),
        headerStyle: { width: 200 },
      },
      {
        dataField: "phoneNumber",
        text: "Số điện thoại",
        headerStyle: { width: 100 },
      },
      {
        dataField: "birthday",
        text: "Ngày sinh",
        formatter: (row) => {
          return formatDateToDisplay(
            row,
            "dd/MM/yyyy",
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
          ) as string;
        },
        headerStyle: { width: 100 },
      },
      {
        dataField: "address",
        text: "Địa chỉ",
        filter: textFilter({
          defaultValue: "",
          delay: 0,
          placeholder: "Nhập địa chỉ",
        }),
        headerStyle: { width: 260 },
      },
      {
        dataField: "identityId",
        text: "CMND",
        filter: textFilter({
          defaultValue: "",
          delay: 0,
          placeholder: "Nhập số CMND",
        }),
        headerStyle: { width: 200 },
      },
      {
        dataField: "categoryName",
        text: "Chuyên mục",
        filter: selectFilter({
          placeholder: "Tất cả",
          options: optionsCategory,
        }),
        headerStyle: { width: 150 },
      },
      {
        dataField: "cultivatedArea",
        text: "Diện tích",
        headerStyle: { width: 100 },
      },
      {
        dataField: "demonstrationArea",
        text: "Diện tích trình diễn",
        headerStyle: { width: 100 },
      },
      {
        dataField: "unitArea",
        text: "Đơn vị",
        headerStyle: { width: 80 },
      },
      {
        dataField: "trainingTurn",
        text: "Số lần tham gia đào tạo",
        headerStyle: { width: 85 },
      },
    ];
  }
  return [
    {
      dataField: "id",
      text: "No #",
      headerStyle: { width: 60 },
    },
    {
      dataField: "name",
      text: "Họ và tên",
      filter: textFilter({
        defaultValue: "",
        delay: 0,
        placeholder: "Nhập họ và tên",
      }),
      headerStyle: { width: 200 },
    },
    {
      dataField: "project",
      text: "Thuộc dự án",
      filter: textFilter({
        defaultValue: "",
        delay: 0,
        placeholder: "Nhập dự án",
      }),
      headerStyle: { width: 200 },
    },
    {
      dataField: "phoneNumber",
      text: "Số điện thoại",
      headerStyle: { width: 100 },
    },
    {
      dataField: "birthday",
      text: "Ngày sinh",
      formatter: (row) => {
        return formatDateToDisplay(
          row,
          "dd/MM/yyyy",
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
        ) as string;
      },
      headerStyle: { width: 100 },
    },
    {
      dataField: "address",
      text: "Địa chỉ",
      filter: textFilter({
        defaultValue: "",
        delay: 0,
        placeholder: "Nhập địa chỉ",
      }),
      headerStyle: { width: 260 },
    },
    {
      dataField: "identityId",
      text: "CMND",
      filter: textFilter({
        defaultValue: "",
        delay: 0,
        placeholder: "Nhập số CMND",
      }),
      headerStyle: { width: 200 },
    },
    {
      dataField: "categoryName",
      text: "Chuyên mục",
      filter: selectFilter({
        placeholder: "Tất cả",
        options: optionsCategory,
      }),
      headerStyle: { width: 150 },
    },
    {
      dataField: "cultivatedArea",
      text: "Diện tích",
      headerStyle: { width: 100 },
    },
    {
      dataField: "demonstrationArea",
      text: "Diện tích trình diễn",
      headerStyle: { width: 100 },
    },
    {
      dataField: "unitArea",
      text: "Đơn vị",
      headerStyle: { width: 80 },
    },
    {
      dataField: "trainingTurn",
      text: "Số lần tham gia đào tạo",
      headerStyle: { width: 85 },
    },
  ];
};

const Farmer = (props: FarmerProps) => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<FarmerState>({
    page: 1,
    data: [],
    totalSize: 0,
    sizePerPage: config.sizePerPage,
    filters: {},
    loading: false,
    loadingExport: false,
  });

  const { id, inUser } = props;

  const ref = React.useRef<{
    sizePerPage?: number;
    page?: number;
    filters?: Obj;
    type?: TableChangeType;
    newState?: TableChangeState<Obj>;
    categoryFilter?: string;
  }>({});

  const { farmerList, exportFarmer } = useSelector(
    (state: State) => ({
      farmerList: state.farmerList,
      exportFarmer: state.exportFarmer,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (id) {
      dispatch(
        queryFarmerList({
          limit: state.sizePerPage,
          user: id,
          page: state.page,
          withCount: true,
        })
      );
    } else {
      dispatch(
        queryFarmerList({
          limit: state.sizePerPage,
          page: state.page,
          withCount: true,
        })
      );
    }
  }, []);


  React.useEffect(() => {
    if (farmerList && farmerList.success && farmerList.response) {
      const { response } = farmerList;
      setState((prevState) => ({
        ...prevState,
        data: response.data as Obj[],
        totalSize: response.totalCount as number,
        page: response.page as number,
        loading: false,
      }));
    }
  }, [farmerList]);

  React.useEffect(() => {
    if (
      exportFarmer &&
      exportFarmer.success &&
      (exportFarmer as Obj)?.export
    ) {
      dispatch({
        type: FARMER_EXPORT_LIST_CLEAR,
      }); setState((prevState) => ({
        ...prevState,
        loadingExport: false,
      }));
    }
  }, [exportFarmer]);

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<Obj>,
    query?: boolean
  ) => {
    let categoryName = ref.current?.categoryFilter;
    if (!query) {
      ref.current.type = type;
      ref.current.sizePerPage = newState.sizePerPage;
      ref.current.page = newState.page;
      ref.current.filters = newState.filters;
      ref.current.newState = newState;

      if (
        newState.filters["categoryName"] !== undefined ||
        (newState.filters["categoryName"] === undefined &&
          ref.current?.categoryFilter) ||
        type !== "filter"
      ) {
        categoryName = newState.filters["categoryName"]?.filterVal as string;
        if (ref.current?.categoryFilter !== categoryName && !query) {
          setState((prevState) => ({
            ...prevState,
            sizePerPage: newState.sizePerPage,
            page: newState.page,
            filters: newState.filters,
            loading: true,
          }));

          const filters: Obj = {};
          Object.keys(newState.filters).forEach((key) => {
            filters[key] = newState.filters[key].filterVal;
          });

          dispatch(
            queryFarmerList({
              limit: newState.sizePerPage,
              page: newState.page,
              withCount: true,
              ...filters,
            })
          );
          ref.current.categoryFilter = categoryName;
        }
      }
    } else {
      if (query) {
        if (ref && ref.current?.newState) {
          setState((prevState) => ({
            ...prevState,
            loading: true,
          }));
          const filters: Obj = {};
          Object.keys(ref.current?.newState?.filters).forEach((key) => {
            filters[key] = ref.current?.newState?.filters[key].filterVal;
          });
          dispatch(
            queryFarmerList({
              limit: ref.current?.newState?.sizePerPage,
              page: ref.current?.newState?.page,
              withCount: true,
              ...filters,
            })
          );
        }
      }
    }
  };

  const onExportExcel = () => {
    const { filters } = ref.current;
    if (filters) {
      const filters: Obj = {};
      Object.keys(filters).forEach((key) => {
        filters[key] = (filters[key] as Obj).filterVal;
      });
    }
    setState((prevState) => ({
      ...prevState,
      loadingExport: true,
    }));
    dispatch(
      exportFarmerList({
        ...filters,
      }));
  }
  return (
    <Container className="Farmer">
      <div className="page-header d-flex justify-content-between align-items-center">
        <div className="page-title">Danh sách nông hộ</div>
        <div className="page-title ml-auto">
          <Button
            onClick={onExportExcel}
            as="a"
            className="btn-light"
            disabled={state.loadingExport}
          >
            {state.loadingExport ? (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
                <></>
              )}
            <FaFileExcel className="Excel" /> Xuất ra file excel
          </Button>
        </div>
      </div>
      <DataTable
        data={state.data}
        columns={columns(inUser)}
        page={state.page}
        totalSize={state.totalSize}
        sizePerPage={state.sizePerPage}
        onTableChange={handleTableChange}
        loading={state.loading}
      />
    </Container>
  );
};

export default Farmer;