import { NEWS_CREATE, NEWS_DELETE, NEWS_LOAD_ITEM, NEWS_QUERY_LIST, NEWS_UPDATE } from 'redux-saga/actions';
import { METHOD } from 'utils/api';
import { watchQuery } from 'utils/sagas';

export function* queryNewsList() {
  return yield watchQuery(
    NEWS_QUERY_LIST,
    '/news',
    METHOD.GET
  );
}

export function* createNews() {
  return yield watchQuery(
    NEWS_CREATE,
    '/news',
    METHOD.POST
  );
}

export function* updateNews() {
  return yield watchQuery(
    NEWS_UPDATE,
    '/news/{id}',
    METHOD.PUT
  );
}

export function* deleteNews() {
  return yield watchQuery(
    NEWS_DELETE,
    '/news/{id}',
    METHOD.DELETE
  );
}

export function* loadNews() {
  return yield watchQuery(
    NEWS_LOAD_ITEM,
    '/news/{id}',
    METHOD.GET
  );
}